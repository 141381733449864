import http from "../../general/httpService";
import api from "../../../constants/config.json";

export async function fetchPropertyAdvertById(campaignId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advertisement/get_advertisement_listing_by_campaign_id/${campaignId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  //console.log("API Response");
  //console.log(response);
  return response;
}

///get_unit_with_images/{property_unit_id}
export async function fetchUnitWithImages(propertyUnitId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advertisement/get_unit_with_images/${propertyUnitId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}
