import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import LoginPage from "./components/portal/authentication/login";
import Dashboard from "./components/portal/dashboard/dashboard";
import ProtectedRoute from "./components/portal/authentication/route/protectedroute";
import { ToastContainer } from "react-toastify";
import LogOut from "./components/portal/authentication/logout";
import NavigationBar from "./components/portal/navigation/navigationbar";
import config from "./constants/config.json";
import PrimaryApplicationForm from "./components/portal/primary/primaryApplicationForm";
import PropertyUnitAdvert from "./components/public/advertisement/profile/propertyUnitAdvert";
import TenantSignUpManagement from "./components/public/rentalSignUp/tenantSignUpManagement";
import { getCurrentUser } from "./services/pre-screening/prescreeningManagementService";
import { getCurrentTenantProfileByEmail } from "./services/authentication/tenantAuth";
import { useTenantProfileContext } from "./services/authentication/contexts/tenantProfile/currentTenantProfileContext";
import RentalContractSignature from "./components/portal/rental/contract/signing/RentalContractSignature";
import StartOfLeaseFeesPage from "./components/portal/payment/oneTime/startOfLease/StartOfLeaseFeesPage";
import { PaymentManagement } from "./components/portal/payment/PaymentManagement.tsx";
import AutoPaySetup from "./components/portal/payment/autopay/AutoPaySetup";
import PaymentDashboard from "./components/portal/payment/dashboard/PaymentDashboard";
import RentPaymentProcess from "./components/portal/payment/process/RentPaymentProcess";
import PaymentConfirmation from "./components/portal/payment/confirmation/PaymentConfirmation";
import { PaymentHistory } from "./components/portal/payment/history/PaymentHistory";
import PaymentReceiptsList from "components/portal/payment/receipt/PaymentReceiptsList";
import ReceiptView from "components/portal/payment/receipt/ReceiptView";
import { useSettings } from "./services/authentication/contexts/settingsContext";

function App() {
  const { settings, setSettings } = useSettings();
  const isDarkMode = settings.isDarkMode;
  const [userToken, setUserToken] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const { tenantProfile, setTenantProfile } = useTenantProfileContext();
  const navigate = useNavigate();

  useEffect(() => {
    //dark mode start
    const theme = isDarkMode ? "dark" : "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
    //dark mode end
  }, [isDarkMode]);

  useEffect(() => {
    getUserToken();
  }, []);

  const getUserToken = async () => {
    try {
      const userToken = await getCurrentUser();

      if (!userToken) {
        navigate("/");
        return null;
      }

      setUserToken(userToken);
      getLoggedInUserProfile();
    } catch (error) {
      console.error(`Error getting user token: ${error}`);
      navigate("/");
    }
  };

  const getLoggedInUserProfile = async () => {
    const userProfile = await getCurrentTenantProfileByEmail();

    if (!userProfile) return null;

    updateLocalStorage(userProfile);

    setUserProfile({
      userId: userProfile.tenant_id,
      firstName: userProfile.first_name,
      lastName: userProfile.last_name,
      emailAddress: userProfile.email,
      profileImg: userProfile.profile_img,
    });

    setTenantProfile(userProfile);

    // Note: These functions are not defined in your original App.jsx
    // If you want to include them, make sure to define these functions
    // getCurrentSubscription();
    // findLastPaymentStatus();
  };

  const updateLocalStorage = (profile) => {
    const items = [
      { key: config.tenant_id, value: profile.tenant_id },
      { key: config.first_name, value: profile.first_name },
      { key: config.last_name, value: profile.last_name },
      { key: config.profile_img, value: profile.profile_img },
      { key: config.stripe_customer_id, value: profile.stripe_customer_id },
    ];

    items.forEach(({ key, value }) => {
      if (value && value !== "" && value !== null && value !== undefined) {
        localStorage.setItem(key, value);
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <div id="layout-wrapper">
          <NavigationBar
            tenantProfile={tenantProfile}
            isDarkMode={isDarkMode}
          />

          <div className="vertical-overlay"></div>
          <div className="layout-width">
            <Routes>
              <Route path="/" element={<LoginPage />}>
                <Route path=":loginCode" element={<LoginPage />} />
              </Route>
              {/* Login but with email */}

              {/*<ProtectedRoute
							user={userToken}
							path='/dashboard'
							element={<Dashboard />}
  />*/}

              <Route path="/advert" element={<PropertyUnitAdvert />}>
                <Route path=":campaignId" element={<PropertyUnitAdvert />} />
              </Route>

              <Route path="/rentalSignUp" element={<TenantSignUpManagement />}>
                <Route
                  path=":campaignId"
                  element={<TenantSignUpManagement />}
                />
              </Route>

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Dashboard isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/primaryApplicationForm"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <PrimaryApplicationForm
                      isDarkMode={isDarkMode}
                    ></PrimaryApplicationForm>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":campaignIdFromUrl"
                  element={<PrimaryApplicationForm />}
                />
              </Route>

              <Route
                path="/leaseSigning"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <RentalContractSignature
                      isDarkMode={isDarkMode}
                    ></RentalContractSignature>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":rentalContractId"
                  element={<RentalContractSignature />}
                />
              </Route>

              <Route
                path="/startOfLeaseFees"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <StartOfLeaseFeesPage
                      isDarkMode={isDarkMode}
                    ></StartOfLeaseFeesPage>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":rentalContractId"
                  element={<StartOfLeaseFeesPage isDarkMode={isDarkMode} />}
                />
              </Route>

              {/* Payment Management Routes */}
              <Route
                path="/paymentManagement"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <PaymentManagement isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="dashboard/:rentalContractId"
                  element={<PaymentDashboard isDarkMode={isDarkMode} />}
                />
                <Route
                  path="process/:rentalContractId"
                  element={<RentPaymentProcess isDarkMode={isDarkMode} />}
                />
                <Route
                  path="schedule/:rentalContractId"
                  element={<AutoPaySetup isDarkMode={isDarkMode} />}
                />
                <Route
                  path="history/:rentalContractId"
                  element={<PaymentHistory isDarkMode={isDarkMode} />}
                />
              </Route>

              {/* Payment Confirmation Route */}
              <Route
                path="/payment/confirmation"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <PaymentConfirmation isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/paymentReceipts"
                element={
                  <ProtectedRoute user={userToken}>
                    <PaymentReceiptsList isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<PaymentReceiptsList isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/rentalReceipt/:propertyUnitId/view/:receiptId"
                element={
                  <ProtectedRoute user={userToken}>
                    <ReceiptView isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route path="/logout" element={<LogOut />} />
            </Routes>

            {/*	<GeneralFooter />*/}
            <footer className="footer ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <script>document.write(new Date().getFullYear())</script> ©
                    Gurenter LLC.
                  </div>
                  <div className="col-sm-6">
                    <div className="text-sm-end d-none d-sm-block">
                      Designed and Developed by Gurenter LLC
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;

const Home = () => {
  return <h2>Home (Protected: authenticated user required)</h2>;
};
