import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Alert, Form, Spinner } from "react-bootstrap";
import {
  setupPaymentSchedule,
  getCurrentSchedule,
} from "../../../../services/payment/tenantPaymentService";
import { PaymentSchedule } from "../../../../services/payment/types";
import PageTitleBox from "components/reusable/title/pageTitleBox";
import { usePageTitle } from "../../../../utils/hooks/usePageTitle";
interface AutoPaySetupProps {
  isDarkMode: boolean;
}
//Should use Plaid to ensure the tenant has the funds and valid account
const AutoPaySetupContent: React.FC<AutoPaySetupProps> = ({ isDarkMode }) => {
  usePageTitle({
    title: "Auto-Pay Setup",
  });
  const navigate = useNavigate();
  const { rentalContractId } = useParams<{ rentalContractId: string }>();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentDay, setPaymentDay] = useState<number>(1);
  const [currentSchedule, setCurrentSchedule] =
    useState<PaymentSchedule | null>(null);

  useEffect(() => {
    const fetchCurrentSchedule = async () => {
      if (!rentalContractId) return;

      try {
        const schedule = await getCurrentSchedule(rentalContractId);
        setCurrentSchedule(schedule);
        if (schedule) {
          setPaymentDay(schedule.payment_day);
        }
      } catch (err) {
        console.error("Error fetching current schedule:", err);
        setError("Failed to load current payment schedule");
      }
    };

    fetchCurrentSchedule();
  }, [rentalContractId]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!rentalContractId) return;

    setProcessing(true);
    setError(null);

    try {
      await setupPaymentSchedule({
        rental_contract_id: rentalContractId,
        is_automatic: true,
        payment_day: paymentDay,
      });

      navigate(`/paymentManagement/dashboard/${rentalContractId}`);
    } catch (err: any) {
      setError(err.message || "An error occurred during setup");
    } finally {
      setProcessing(false);
    }
  };

  const paymentDayOptions = Array.from({ length: 28 }, (_, i) => i + 1);

  return (
    <div className="page-content">
      <PageTitleBox
        pageTitle="Auto-Pay Setup"
        previousPageTitle="Payment Dashboard"
        previousPageLink={`/paymentManagement/dashboard/${rentalContractId}`}
        isDarkMode={isDarkMode}
      />
      <Card
        bg={isDarkMode ? "dark" : "light"}
        text={isDarkMode ? "white" : "dark"}
      >
        <Card.Header>
          <Card.Title>
            {currentSchedule?.is_automatic
              ? "Update Auto-Pay Settings"
              : "Set Up Automatic Payments"}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <Alert variant="info" className="mb-4">
              By setting up automatic payments, you agree to have your rent
              automatically charged on day {paymentDay} of each month. You can
              update or cancel this at any time.
            </Alert>

            <div className="d-flex gap-2">
              <Button
                variant="primary"
                type="submit"
                disabled={processing}
                className="flex-grow-1"
              >
                {processing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    {currentSchedule?.is_automatic
                      ? "Updating..."
                      : "Setting Up..."}
                  </>
                ) : currentSchedule?.is_automatic ? (
                  "Update Auto-Pay"
                ) : (
                  "Enable Auto-Pay"
                )}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() =>
                  navigate(`/paymentManagement/dashboard/${rentalContractId}`)
                }
                disabled={processing}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export const AutoPaySetup: React.FC<AutoPaySetupProps> = (props) => {
  return <AutoPaySetupContent {...props} />;
};

export default AutoPaySetup;
