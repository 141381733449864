//TODO: Landlords need the ability to end contract early with the signing by all parties
//Purpose: This is where we get contracts to be signed by the tenant, view current and past contracts
//contracts are past when is_active is false and or the date_contract_ends is in the past
//contracts are current when is_active is true and the date_contract_ends is in the future
//contracts are future when is_active is true and the date_contract_ends is in the future
import http from "../../general/httpService";
import config from "../../../constants/config.json";

///tenant_rental_contract/get_latest_pending_rental_contract_by_tenant/{tenant_id}/{campaign_id}/{property_unit_id}
export async function getLatestPendingRentalContractByTenant(
  tenantId,
  campaignId,
  propertyUnitId
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_latest_pending_rental_contract_by_tenant/${tenantId}/${campaignId}/${propertyUnitId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

///get_rental_contract_by_id/{rental_contract_id}
export async function getRentalContractById(rentalContractId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_rental_contract_by_id/${rentalContractId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

//update_rental_contract_with_tenant_signature
export async function updateRentalContractWithTenantSignature(data) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/update_rental_contract_with_tenant_signature`;
  const { data: response } = await http.post(fullApiEndpoint, data);
  return response;
}

///get_latest_rental_contract/{tenant_id}/{campaign_id}/{property_unit_id}
export async function getLatestRentalContract(
  tenantId,
  campaignId,
  propertyUnitId
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_latest_rental_contract/${tenantId}/${campaignId}/${propertyUnitId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}
