import React, { useState, useEffect } from "react";
import { Card, Button, Alert, Form, Spinner } from "react-bootstrap";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentIntent } from "../../../../services/payment/types";
import {
  createPaymentIntent,
  confirmPaymentIntent,
  processRentPayment,
} from "../../../../services/payment/tenantPaymentService";
import { formatCurrency } from "../../../../utils/currencyUtils";
import { STRIPE_PUBLISHABLE_KEY } from "../../../../constants/constants";
import config from "../../../../constants/config.json";
import { getRentalContractById } from "services/rental/contract/rentalContractService";
import { getRentalFeePackById } from "services/rental/fees/rentalFeePackService";
import { getCurrencyById } from "services/systemDefined/currency/currencyService";
import { usePageTitle } from "../../../../utils/hooks/usePageTitle";
interface PaymentFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  isDarkMode: boolean;
  clientSecret?: string;
}

// Initialize Stripe
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY!);

export const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  if (!stripePromise) {
    return <Alert variant="danger">Stripe configuration is missing</Alert>;
  }

  if (!props.clientSecret) {
    return (
      <div className="text-center py-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: props.clientSecret,
        appearance: {
          theme: props.isDarkMode ? "night" : "stripe",
        },
      }}
    >
      <PaymentFormContent {...props} />
    </Elements>
  );
};

const PaymentFormContent: React.FC<PaymentFormProps> = ({
  onSuccess,
  onCancel,
  isDarkMode,
  clientSecret,
}) => {
  usePageTitle({
    title: "Process Payment",
  });
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setError(null);

    try {
      // First submit the payment element
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      // Confirm the payment with Stripe
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment/confirmation`,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      onSuccess();
    } catch (err: any) {
      setError(err.message || "An error occurred during payment processing");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Card
      bg={isDarkMode ? "dark" : "light"}
      text={isDarkMode ? "white" : "dark"}
    >
      <Card.Header>
        <Card.Title>Process Payment</Card.Title>
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <div className="mb-4">
            <PaymentElement />
          </div>

          <div className="d-flex gap-2">
            <Button
              variant="primary"
              type="submit"
              disabled={!stripe || processing}
              className="flex-grow-1"
            >
              {processing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Processing...
                </>
              ) : (
                "Pay Now"
              )}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={onCancel}
              disabled={processing}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PaymentForm;
