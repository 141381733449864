export const getActionTypeText = (actionType) => {
  switch (actionType) {
    case "none":
      return "No action required";
    case "schedule_viewing":
      return "Schedule Viewing";
    case "complete_application":
      return "Complete Application";
    case "upload_document":
      return "Upload Document";
    case "schedule_interview":
      return "Schedule Interview";
    case "sign_lease":
      return "Sign Lease";
    case "submit_payment":
      return "Submit Payment";
    case "schedule_move_in":
      return "Schedule Move-In";
    case "report_issue":
      return "Report Issue";
    case "request_maintenance":
      return "Request Maintenance";
    case "update_contact_info":
      return "Update Contact Info";
    case "review_policies":
      return "Review Policies";
    case "confirm_details":
      return "Confirm Details";
    case "inspection":
      return "Inspection";
    case "utility_setup":
      return "Utility Setup";
    case "key_collection":
      return "Key Collection";
    default:
      return "Perform Action";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    case "In Progress":
      return "warning";
    default:
      return "default";
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "in_progress":
      return "In Progress";
    case "hard_rejected":
      return "Rejected";
    case "awaiting_lease_signing":
      return "Awaiting Lease Signing";
    case "payment_verification":
      return "Rental Contract Fees";
    case "move_in_scheduled":
      return "Move-In Scheduled";
    case "contract_started":
      return "Contract Started";
    default:
      return "Pending";
  }
};

export const getApplicationStatusButtonText = (applicationStatus) => {
  switch (applicationStatus.toUpperCase()) {
    case "NOT_STARTED":
      return "Start Application";
    case "IN_PROGRESS":
      return "Continue Application";
    case "SUBMITTED":
      return "View Application";
    case "APPROVED":
      return "View Approved Application";
    case "REJECTED":
      return "View Application Status";
    case "HARD_REJECTED":
      return "View Application Status";
    case "AWAITING_LEASE_SIGNING":
      return "Sign Lease";
    case "PAYMENT_VERIFICATION":
      return "Pay Fees"; //from tenant side
    case "MOVE_IN_SCHEDULED":
      return "Schedule Move-In";
    case "CONTRACT_STARTED":
      return "View Lease";
    case "PAYMENT_VERIFICATION_REQUIRED":
      return "Verification In Progress";
    default:
      return "View Application";
  }
};

//set button bootrap color
export const getButtonBootstrapColor = (applicationStatus) => {
  switch (applicationStatus.toUpperCase()) {
    case "NOT_STARTED":
      return "primary";
    case "IN_PROGRESS":
      return "warning";
    case "SUBMITTED":
      return "success";
    case "APPROVED":
      return "success";
    case "REJECTED":
      return "danger";
    case "HARD_REJECTED":
      return "danger";
    case "AWAITING_LEASE_SIGNING":
      return "info";
    case "PAYMENT_VERIFICATION":
      return "info";
    case "MOVE_IN_SCHEDULED":
      return "info";
    case "CONTRACT_STARTED":
      return "info";
    default:
      return "primary";
  }
};

//TODO: discuss all these statuses with the team
//button navigation
export const getButtonNavigation = (
  applicationStatus,
  campaignId,
  rentalContractId
) => {
  const status = applicationStatus.toUpperCase();

  // Create object with navigation and disabled state
  const buttonConfig = {
    path: "",
    disabled: false,
  };

  switch (status) {
    case "NOT_STARTED":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "IN_PROGRESS":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "SUBMITTED":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "APPROVED":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "REJECTED":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "HARD_REJECTED":
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
      break;
    case "AWAITING_LEASE_SIGNING":
      buttonConfig.path = rentalContractId
        ? `/leaseSigning/${rentalContractId}`
        : "/dashboard";
      break;
    case "PAYMENT_VERIFICATION":
      buttonConfig.path = `/startOfLeaseFees/${rentalContractId}`;
      break;
    case "MOVE_IN_SCHEDULED":
      buttonConfig.path = "/dashboard";
      break;
    case "CONTRACT_STARTED":
      buttonConfig.path = "/dashboard";
      break;
    case "PAYMENT_VERIFICATION_REQUIRED":
      buttonConfig.path = "/dashboard";
      buttonConfig.disabled = true; // Only this status disables the button
      break;
    default:
      buttonConfig.path = `/primaryApplicationForm/${campaignId}`;
  }

  return buttonConfig;
};
