import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import SignaturePad from "./sub/SignaturePad";
import SignatureUpload from "./sub/SignatureUpload";
import DocumentList from "./sub/DocumentList";
import { PencilFill, Upload } from "react-bootstrap-icons";
import {
  getRentalContractById,
  updateRentalContractWithTenantSignature,
} from "../../../../../services/rental/contract/rentalContractService";

import HtmlPreview from "../../../../reusable/preview/HtmlPreview";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import { replaceTemplateVariables } from "../../../../../utils/templateReplacer";
import { getCurrentTenantProfileByEmail } from "../../../../../services/authentication/tenantAuth";
import { getRentalFeePackById } from "../../../../../services/rental/fees/rentalFeePackService";
import config from "../../../../../constants/config.json";
import { SignatureModal } from "./sub/SignatureModal";
import { devLog } from "utils/logger";
import { useThemeClasses } from "utils/hooks/useThemeClasses";
import LoaderView from "components/reusable/loading/loaderView";

//TODO: continue working on this its missing styling and libraries etc" Also Need to let the tenant section be enabled
//Also tenant cant sign the landlord section

//Need to be able to before saving the contract know who are the people that need to sign the contract
//Then need to fetch the rental contract from the backend to populate the document list, once the signature is saved then we need to update the rental contract with the signature
//Then we need to send the rental contract to the tenant and other parties

//TODO: need to when someone presses save send the signature to the backend and then update the rental contract with the signature
//then test that i can refresh the page and the signature is still there, also need to add the shortcut on the dashboard (visible
//once your a signer and not all parties have signed) , after you can see it on the property detail page under the contract section
//THe save button doesnt need to be present if its an image upload

//TODO: next need the short cut then the tenant portal then enforce the tenant to sign the contract before the landlord can
const RentalContractSignature = ({ isDarkMode }) => {
  const { rentalContractId } = useParams();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [signatureMode, setSignatureMode] = useState("draw");

  //TODO:  first Highlight the section the user (landlord) is signing, disable the other sections the user cannot sign
  //Later, add restrictions so the the contract cannot be signed until the tenant has signed (all tenants need to sign)
  //Need to add this contract signing feature to the tenant portal as well (shows on the dashboard if approved applicant)

  //TODO NEXT: enable the user thats associated with the signature to be able to sign (in this case is the landlord)
  const [documents, setDocuments] = useState([]);

  // Add new state for current user
  const [currentUserId, setCurrentUserId] = useState(() => {
    const storedId = localStorage.getItem(config.tenant_id);
    return storedId || null; // Returns null if no ID is found
  });

  const [rentalContract, setRentalContract] = useState({
    rental_contract_id: "",
    tenant_id: "",
    property_unit_id: "",
    start_date: "",
    end_date: "",
    monthly_rent: 0,
    security_deposit: 0,
    contract_template_id: "",
    contract_detail: "",
    portfolio_ownership_id: "",
    property_name: "",
    property_unit_name: "",
    portfolio_name: "",
    owner_manager_user_id: "",
    owner_manager_name: "",
    owner_manager_email: "",
    end_of_lease_action: "",
    is_active: true,
    is_deleted: false,
    rental_fee_pack_template_id: "",
    rental_fee_pack_id: "",
    screening_pack_id: "",
    campaign_id: "",
    //Need to prefill who needs to sign the contract
    landlord_property_manager_signatures: [
      //TODO: next saving the signature for the logged in user and disable the others for the logged in user
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "", //aws s3 url
        signature_base64: "", //base64 string (not for storage )
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_id: "",
        user_type: "",
        landlord_id: "",
        email_address: "",
      },
    ],
    tenant_signatures: [
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "",
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_type: "",
        tenant_id: "",
        email_address: "",
      },
    ],
  });

  const [tenantProfile, setTenantProfile] = useState({});

  const [rentalFeePack, setRentalFeePack] = useState({
    rental_fee_pack_template_id: "",
    fee_pack_name: "",
    fee_pack_description: "",
    require_security_deposit: false,
    require_last_month_rent: false,
    require_pet_deposit: false,
    require_pet_rent: false,
    collect_prorated_rent: false,
    automatically_increase_rent: false,
    rent_increase_percentage: 0,
    rent_increase_period: "",
    rent_paid_through_gurenter: false,
    require_guarantor: false,
    bank_account_id: "",
    bank_id: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_routing_number: "",
    is_bank_verified: false,
    created_at: Date(),
    created_by: "",
    user_id: "",
    user_defined_charges: [
      {
        charge_name: "",
        charge_amount: 0,
      },
    ],
  });

  const fetchRentalContract = async () => {
    const rentalContractResult = await getRentalContractById(rentalContractId);
    if (rentalContractResult) {
      devLog("Rental Contract: ", rentalContractResult);
      setRentalContract(rentalContractResult);
      if (rentalContractResult.tenant_id) {
        // First fetch tenant profile
        const tenantProfileResult = await fetchTenantProfile(
          rentalContractResult.tenant_id
        );
        if (tenantProfileResult) {
          setTenantProfile(tenantProfileResult);
          // Then setup document signers after we have tenant data
          const updatedDocuments = setupDocumentSigners(
            rentalContractResult,
            tenantProfileResult
          );
          setDocuments(updatedDocuments);

          fetchRentalFeePack(rentalContractResult.rental_fee_pack_id);
        }
      }
    }
  };

  const handleSignatureUpload = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      setError("Please upload an image file");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setSignature(reader.result);
      setError("");
    };
    reader.onerror = () => setError("Error reading file");
    reader.readAsDataURL(file);
  };

  const handleSign = async () => {
    if (!signature) {
      setError("Please provide a signature first");
      return;
    }

    try {
      // Find the current tenant's signature entry
      const currentTenantSignature = rentalContract.tenant_signatures.find(
        (sig) => sig.tenant_id === currentUserId
      );

      if (!currentTenantSignature) {
        setError("Tenant signature entry not found");
        return;
      }

      // Prepare signature data
      const signatureData = {
        signature_id: currentTenantSignature.signature_id || undefined,
        signature_base64: signature,
        rental_contract_id: rentalContractId,
        signed_at: new Date().toISOString(),
        signed_by: `${tenantProfile?.first_name} ${tenantProfile?.last_name}`,
        is_signed: true,
        user_type: "tenant",
        tenant_id: currentUserId,
        email_address:
          currentTenantSignature.email_address ||
          tenantProfile.profile?.email_address,
      };

      // Save signature to backend
      await updateRentalContractWithTenantSignature(signatureData);

      // Update local state and close modal
      setIsModalOpen(false);
      setSignature("");
      setSignatureMode("draw");

      // Refresh contract data
      await fetchData();

      //TODO: check if all parties have signed and if yes then change the application status from AWAITING_LEASE_SIGNING to PAYMENT_VERIFICATION
      //THe landlord should have this ability as well since its who ever signs last
      //TODO: Next phase is to let the tenant pay and the Landlord confirm payment to start the lease
    } catch (error) {
      devLog("Error saving signature:", error);
      setError("Failed to save signature. Please try again.");
    }
  };

  const handleSaveSignature = () => {
    if (signature) {
      handleSign();
      setIsModalOpen(false);
    }
  };

  const fetchTenantProfile = async (_tenant_id) => {
    const result = await getCurrentTenantProfileByEmail();
    if (result) {
      devLog("Tenant Profile: ", result);
      setTenantProfile(result);
      return result;
    }
    return null;
  };

  const fetchRentalFeePack = async (_rental_fee_pack_id) => {
    const result = await getRentalFeePackById(_rental_fee_pack_id);
    if (result) {
      devLog("Rental Fee Pack: ", result);
      setRentalFeePack(result);
    }
  };

  const canUserSign = async (signature) => {
    // Only check tenant signatures
    if (!signature || signature.userType !== "tenant") {
      return false;
    }

    // Check if this signature belongs to the current tenant and is unsigned
    return signature.userId === currentUserId && !signature.status === "signed";
  };

  // New function to setup document signers
  const setupDocumentSigners = (contract, _tenantProfile) => {
    if (!contract) return [];
    const signers = [];

    // Handle tenant signatures first
    if (contract.tenant_signatures?.length > 0) {
      contract.tenant_signatures.forEach((tenantSig) => {
        const isCurrentTenant = tenantSig.tenant_id === currentUserId;
        const isUnsigned = !tenantSig.is_signed;

        signers.push({
          id: tenantSig.signature_id || `tenant-sig-${tenantSig.tenant_id}`,
          name:
            tenantSig.signed_by ||
            `${_tenantProfile?.first_name || ""} ${
              _tenantProfile?.last_name || ""
            }`.trim() ||
            `Tenant`,
          userType: "tenant",
          status: tenantSig.is_signed ? "signed" : "pending",
          signedAt: tenantSig.signed_at
            ? new Date(tenantSig.signed_at).toLocaleDateString()
            : null,
          signatureUrl: tenantSig.signature_url || "",
          showSignButton: isCurrentTenant && isUnsigned,
          highlight: isCurrentTenant && isUnsigned,
          email: tenantSig.email_address,
          userId: tenantSig.tenant_id,
        });
      });
    }

    // Add landlord signatures as read-only display
    if (contract.landlord_property_manager_signatures?.length > 0) {
      contract.landlord_property_manager_signatures.forEach((landlordSig) => {
        signers.push({
          id: `landlord-${landlordSig.user_id}`,
          name: landlordSig.signed_by || contract.owner_manager_name,
          userType: "landlord",
          status: landlordSig.is_signed ? "signed" : "pending",
          signedAt: landlordSig.signed_at
            ? new Date(landlordSig.signed_at).toLocaleDateString()
            : null,
          signatureUrl: landlordSig.signature_url || "",
          showSignButton: false,
          highlight: false,
          email: landlordSig.email_address,
          userId: landlordSig.user_id,
        });
      });
    }

    return signers;
  };

  // Update fetchData to include document setup
  const fetchData = async () => {
    try {
      setIsLoading(true);
      await fetchRentalContract();
    } catch (error) {
      devLog("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /*
Example of use
const customMappings = {
  '@TenantName': 'John Doe',
  '@UtilitiesList': 'Water, Electricity, Internet',
  '@NoticePeriod': '30 days'
};

const processed = replaceTemplateVariables(content, data, customMappings);

*/
  //Next map the other variables and add the custom workflow where the tenants must sign the contract before the landlord can
  //Make this contact have a quick link on the dashboard and on that property for the landlord
  //rentalContractSignature/0a80fd7b-727e-42d7-82d2-6ae84dc56e74
  //Once the contract is created, send the tenant an email that they need to sign the contract, show the same screen but
  //the landlord will not be able to sign until the tenant has signed (all the tenants need to sign first)
  //the landlord signature will be last
  //Use the same control on the tenant portal but the tenant can only sign their part
  //The tenant will have a link to the contract on their dashboard
  //The landlord will have a link to the contract on their dashboard and on the property detail page

  const customMappings = {
    "@TenantName": tenantProfile?.first_name + " " + tenantProfile?.last_name,
    "@AdditionalCharges": rentalFeePack.user_defined_charges
      .map((charge) => `${charge.charge_name}: $${charge.charge_amount}`)
      .join(", "),
  };

  const processedContractDetail = rentalContract?.contract_detail
    ? replaceTemplateVariables(
        rentalContract.contract_detail,
        rentalContract,
        customMappings
      )
    : "";

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <LoaderView />}

      <div className={`page-content ${getThemeClasses()}`}>
        <PageTitleBox
          pageTitle="Sign Rental Contract"
          previousPageTitle="Applicant Pool"
          previousPageLink={`/applicantPoolDetail/${
            rentalContract?.tenant_id || ""
          }`}
        />

        <div className="card">
          <div className="card-body">
            <HtmlPreview
              isDarkMode={isDarkMode}
              htmlContent={processedContractDetail}
              height="400px"
              width="100%"
              padding="40px"
              className="tw-bg-white tw-shadow-sm tw-border tw-border-slate-200 tw-rounded-lg tw-overflow-y-auto tw-text-sm tw-leading-relaxed tw-text-gray-900 tw-scroll-smooth hover:tw-shadow-md tw-transition-shadow"
            />
            <DocumentList
              documents={documents}
              onSignClick={() => setIsModalOpen(true)}
              isDarkMode={isDarkMode}
            />
          </div>

          <SignatureModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSign={handleSign}
            signature={signature}
            setSignature={setSignature}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </>
  );
};

export default RentalContractSignature;
