import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { createPaymentIntent } from "../../../../services/payment/tenantPaymentService";
import { formatCurrency } from "../../../../utils/currencyUtils";
import { PaymentForm } from "./PaymentForm";
import { getRentalContractById } from "../../../../services/rental/contract/rentalContractService";
import { getRentalFeePackById } from "../../../../services/rental/fees/rentalFeePackService";
import { getCurrencyById } from "../../../../services/systemDefined/currency/currencyService";
import { getNextPaymentDate } from "../../../../services/payment/tenantPaymentService";
import { devLog } from "utils/logger";

interface RentPaymentProps {
  isDarkMode: boolean;
}

export const RentPaymentProcess: React.FC<RentPaymentProps> = ({
  isDarkMode,
}) => {
  const { rentalContractId } = useParams<{ rentalContractId: string }>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState<{
    baseRent: number;
    totalDue: number;
    dueDate: Date;
    rentalFeePackId: string;
    currency: string;
  } | null>(null);
  const [isPaymentDetailsReady, setIsPaymentDetailsReady] = useState(false);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      if (!rentalContractId) {
        setError("No rental contract specified");
        return;
      }

      try {
        setIsProcessing(true);
        setError(null);

        // Get rental contract details
        const rentalContract = await getRentalContractById(rentalContractId);
        devLog("Rental contract fetched:", rentalContract);
        if (!rentalContract) {
          throw new Error("Rental contract not found");
        }

        // Get next payment details
        const nextPayment = await getNextPaymentDate(rentalContractId);
        devLog("Next payment details:", nextPayment);
        if (!nextPayment || nextPayment.amount <= 0) {
          throw new Error("Invalid payment amount");
        }

        // Get fee pack details
        const feePack = await getRentalFeePackById(
          rentalContract.rental_fee_pack_id
        );
        devLog("Fee pack fetched:", feePack);
        if (!feePack) {
          throw new Error("Fee pack not found");
        }

        // Get currency details
        const currency = await getCurrencyById(feePack.currency_id);
        devLog("Currency fetched:", currency);
        if (!currency) {
          throw new Error("Currency not found");
        }

        // Set payment details
        const details = {
          baseRent: nextPayment.amount,
          totalDue: nextPayment.amount,
          dueDate: new Date(nextPayment.next_payment_date),
          rentalFeePackId: feePack.rental_fee_pack_template_id,
          currency: currency.currency_code,
        };

        setPaymentDetails(details);
        setIsPaymentDetailsReady(true);
      } catch (err: any) {
        console.error("Error fetching payment details:", err);
        devLog("Payment details fetch error:", err);
        setError(err.message || "Failed to fetch payment details");
        setIsPaymentDetailsReady(false);
      } finally {
        setIsProcessing(false);
      }
    };

    fetchPaymentDetails();
  }, [rentalContractId]);

  useEffect(() => {
    const createIntent = async () => {
      if (!isPaymentDetailsReady || !paymentDetails || !rentalContractId) {
        return;
      }

      try {
        setIsProcessing(true);
        setError(null);

        devLog("Creating payment intent with:", {
          amount: paymentDetails.totalDue,
          currency: paymentDetails.currency,
          rental_fee_pack_id: paymentDetails.rentalFeePackId,
        });

        const intent = await createPaymentIntent(rentalContractId, {
          amount: paymentDetails.totalDue,
          currency: paymentDetails.currency,
          rental_fee_pack_id: paymentDetails.rentalFeePackId,
        });

        devLog("Payment intent created:", intent);
        setClientSecret(intent.client_secret);
      } catch (err: any) {
        console.error("Error creating payment intent:", err);
        devLog("Payment intent creation error:", err);
        setError(err.message || "Failed to create payment intent");
      } finally {
        setIsProcessing(false);
      }
    };

    createIntent();
  }, [isPaymentDetailsReady, paymentDetails, rentalContractId]);

  if (!rentalContractId) {
    return (
      <div className="tw-rounded-lg tw-bg-red-50 tw-p-4 tw-text-red-500">
        No rental contract specified
      </div>
    );
  }

  return (
    <div className="tw-container tw-mx-auto tw-px-4 tw-py-8">
      <div className="tw-max-w-6xl tw-mx-auto">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
          <h2 className="tw-text-2xl tw-font-bold">Process Rent Payment</h2>
          <button
            onClick={() => window.history.back()}
            className={`tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-flex tw-items-center tw-justify-center
              ${
                isDarkMode
                  ? "tw-border-gray-600 tw-text-gray-300 hover:tw-bg-gray-700"
                  : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
              }`}
          >
            <svg
              className="tw-w-4 tw-h-4 tw-mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Cancel
          </button>
        </div>

        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          {/* Left Column - Payment Summary */}
          <div
            className={`tw-rounded-xl tw-shadow-lg ${
              isDarkMode
                ? "tw-bg-gray-800 tw-text-white"
                : "tw-bg-white tw-text-gray-900"
            }`}
          >
            <div className="tw-p-6">
              {paymentDetails && (
                <div>
                  <div
                    className={`tw-rounded-lg ${
                      isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                    } tw-p-6`}
                  >
                    <h3 className="tw-text-lg tw-font-semibold tw-mb-4">
                      Payment Summary
                    </h3>
                    <div className="tw-space-y-4">
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <span
                          className={`${
                            isDarkMode ? "tw-text-gray-300" : "tw-text-gray-500"
                          }`}
                        >
                          Base Rent:
                        </span>
                        <span className="tw-font-semibold">
                          {formatCurrency(
                            paymentDetails.baseRent,
                            paymentDetails.currency
                          )}
                        </span>
                      </div>
                      <div className="tw-flex tw-justify-between tw-items-center tw-pt-4 tw-border-t tw-border-gray-200">
                        <span className="tw-font-bold">Total Due:</span>
                        <span className="tw-font-bold tw-text-xl">
                          {formatCurrency(
                            paymentDetails.totalDue,
                            paymentDetails.currency
                          )}
                        </span>
                      </div>
                      <div
                        className={`tw-flex tw-items-center tw-text-sm ${
                          isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                        }`}
                      >
                        <svg
                          className="tw-w-4 tw-h-4 tw-mr-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                        Due Date: {paymentDetails.dueDate.toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Right Column - Payment Form */}
          <div
            className={`tw-rounded-xl tw-shadow-lg ${
              isDarkMode
                ? "tw-bg-gray-800 tw-text-white"
                : "tw-bg-white tw-text-gray-900"
            }`}
          >
            <div className="tw-p-6">
              {error && (
                <div className="tw-mb-6 tw-rounded-lg tw-bg-red-50 tw-p-4 tw-text-red-500">
                  <div className="tw-flex tw-items-center">
                    <svg
                      className="tw-w-5 tw-h-5 tw-mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    {error}
                  </div>
                </div>
              )}

              {isProcessing ? (
                <div className="tw-flex tw-items-center tw-justify-center tw-h-64">
                  <div className="tw-flex tw-flex-col tw-items-center">
                    <svg
                      className="tw-animate-spin tw-h-8 tw-w-8 tw-mb-4"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="tw-opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="tw-opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span className="tw-text-lg">Initializing payment...</span>
                  </div>
                </div>
              ) : clientSecret ? (
                <PaymentForm
                  clientSecret={clientSecret}
                  onSuccess={() => {
                    toast.success(
                      "Your rent payment has been processed successfully"
                    );
                    window.history.back();
                  }}
                  onCancel={() => window.history.back()}
                  isDarkMode={isDarkMode}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentPaymentProcess;
