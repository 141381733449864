import { Link } from "react-router-dom";
import { useThemeClasses } from "../../../utils/hooks/useThemeClasses";

const PageTitleBox = ({
  previousPageTitle = "Pages",
  pageTitle,
  previousPageLink,
  isDarkMode,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  return (
    <div className="row px-4 tw-py-8">
      <div className="col-12">
        <div
          className={`page-title-box d-sm-flex align-items-center justify-content-between ${getThemeClasses()}`}
        >
          <h4 className="mb-sm-0">{pageTitle}</h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={previousPageLink}>{previousPageTitle}</Link>
              </li>
              <li className="breadcrumb-item active">{pageTitle}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBox;
