import React, { useState, useEffect, Fragment } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import profileImg from "../../../styletheme/images/users/multi-user.jpg";
import config from "../../../constants/config.json";
import logo from "../../../styletheme/logos/gurenter_logo.png";
import { getCurrentUser } from "../../../services/pre-screening/prescreeningManagementService";
import { useSettings } from "../../../services/authentication/contexts/settingsContext";
import { useThemeClasses } from "../../../utils/hooks/useThemeClasses";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavigationBar = (props) => {
  const { tenantProfile, isDarkMode } = props;
  const { settings, setSettings } = useSettings();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const rentalContractId = localStorage.getItem(config.rentalContractId);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(!!getCurrentUser());

  const [userData, setUserData] = useState({
    first_name: localStorage.getItem(config.first_name) || "",
    last_name: localStorage.getItem(config.last_name) || "",
    email: localStorage.getItem(config.email) || "",
  });

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const toggleDarkMode = () => {
    setSettings((prevSettings) => {
      const newDarkMode = !prevSettings.isDarkMode;
      // Save to localStorage
      localStorage.setItem(config.is_dark_mode, newDarkMode.toString());
      return {
        ...prevSettings,
        isDarkMode: newDarkMode,
      };
    });
  };

  const handleLogout = () => {
    // Clear the tenant profile
    if (tenantProfile) {
      tenantProfile = {};
    }
    //clear all localStorage individually
    localStorage.removeItem(config.first_name);
    localStorage.removeItem(config.last_name);
    localStorage.removeItem(config.email);
    localStorage.removeItem(config.rentalContractId);
    localStorage.removeItem(config.is_dark_mode);
    localStorage.removeItem(config.access_token);
    localStorage.removeItem(config.refresh_token);
    localStorage.removeItem(config.tenant_id);
    localStorage.removeItem(config.user_type);
    localStorage.removeItem(config.stripe_customer_id);
    localStorage.removeItem(config.is_paid);
    localStorage.removeItem(config.login_code);
    localStorage.removeItem(config.profile_img);

    setIsAuthenticated(false);
  };

  const navigation = isAuthenticated
    ? [
        { name: "Dashboard", href: "/dashboard" },
        {
          name: "Primary Application Form",
          href: "/primaryApplicationForm",
        },
        ...(rentalContractId
          ? [
              {
                name: "Payments",
                items: [
                  {
                    name: "Payment Dashboard",
                    href: `/paymentManagement/dashboard/${rentalContractId}`,
                  },
                  {
                    name: "Make a Payment",
                    href: `/paymentManagement/process/${rentalContractId}`,
                  },
                  {
                    name: "Auto-Pay Setup",
                    href: `/paymentManagement/schedule/${rentalContractId}`,
                  },
                  {
                    name: "Payment History",
                    href: `/paymentManagement/history/${rentalContractId}`,
                  },
                ],
              },
              {
                name: "Rental Receipts",
                href: "/paymentReceipts",
              },
            ]
          : []),
      ]
    : [];

  const authButtons = !isAuthenticated ? (
    <div className="tw-flex tw-items-center tw-gap-4">
      <Link
        to="/advert"
        className={classNames(
          isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900",
          "tw-text-sm tw-font-semibold"
        )}
      >
        Property Advertisement
      </Link>
      <Link
        to="/"
        className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
      >
        Login
      </Link>
    </div>
  ) : null;

  return (
    <Disclosure
      as="nav"
      className={`tw-sticky tw-top-0 tw-z-50 tw-shadow tw-w-full tw-m-0 tw-p-0 ${getThemeClasses()}`}
    >
      {({ open }) => (
        <>
          <div className="tw-mx-0 tw-px-0">
            <div className="tw-flex tw-h-16 tw-justify-between tw-px-4">
              <div className="tw-flex tw-items-center">
                <div className="tw-flex tw-shrink-0 tw-items-center">
                  <Link to={isAuthenticated ? "/dashboard" : "/"}>
                    <img
                      src={logo}
                      alt="Gurenter Logo"
                      className="tw-h-10 tw-w-auto"
                    />
                  </Link>
                </div>

                {isAuthenticated && (
                  <div className="tw-hidden lg:tw-ml-8 lg:tw-flex lg:tw-space-x-8">
                    {navigation.map((item) =>
                      !item.items ? (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? isDarkMode
                                ? "tw-border-blue-400 tw-text-blue-400"
                                : "tw-border-indigo-500 tw-text-indigo-700"
                              : isDarkMode
                              ? "tw-border-transparent tw-text-gray-300 hover:tw-border-gray-600 hover:tw-text-white"
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700",
                            "tw-inline-flex tw-items-center tw-border-b-2 tw-px-1 tw-py-4 tw-text-sm tw-font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <Menu
                          as="div"
                          key={item.name}
                          className="tw-relative tw-inline-block"
                        >
                          <Menu.Button
                            className={classNames(
                              location.pathname.startsWith(
                                item.items[0].href.split("/")[1]
                              )
                                ? isDarkMode
                                  ? "tw-border-blue-400 tw-text-blue-400"
                                  : "tw-border-indigo-500 tw-text-indigo-700"
                                : isDarkMode
                                ? "tw-border-transparent tw-text-gray-300 hover:tw-border-gray-600 hover:tw-text-white"
                                : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700",
                              "tw-inline-flex tw-items-center tw-border-b-2 tw-px-1 tw-py-4 tw-text-sm tw-font-medium"
                            )}
                          >
                            {item.name}
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="tw-transition tw-ease-out tw-duration-100"
                            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                            enterTo="tw-transform tw-opacity-100 tw-scale-100"
                            leave="tw-transition tw-ease-in tw-duration-75"
                            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                          >
                            <Menu.Items
                              className={classNames(
                                "tw-absolute tw-left-0 tw-z-[1000] tw-mt-2 tw-w-56 tw-origin-top-left tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none",
                                isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
                              )}
                            >
                              <div className="tw-py-1">
                                {item.items.map((subItem) => (
                                  <Menu.Item key={subItem.name}>
                                    {({ active }) => (
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          location.pathname === subItem.href
                                            ? isDarkMode
                                              ? "tw-bg-gray-900 tw-text-blue-400"
                                              : "tw-bg-indigo-50 tw-text-indigo-700"
                                            : active
                                            ? isDarkMode
                                              ? "tw-bg-gray-700 tw-text-white"
                                              : "tw-bg-gray-100 tw-text-gray-900"
                                            : isDarkMode
                                            ? "tw-text-gray-300"
                                            : "tw-text-gray-700",
                                          "tw-block tw-px-4 tw-py-2 tw-text-sm"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="tw-flex tw-items-center tw-gap-2">
                {!isAuthenticated ? (
                  authButtons
                ) : (
                  <>
                    <div className="tw-flex tw-items-center lg:tw-hidden">
                      <Disclosure.Button className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500 dark:hover:tw-bg-gray-700">
                        <span className="tw-absolute tw--inset-0.5" />
                        <span className="tw-sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="tw-block tw-h-6 tw-w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="tw-block tw-h-6 tw-w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>

                    <div className="tw-hidden lg:tw-flex lg:tw-items-center lg:tw-gap-3">
                      <button
                        type="button"
                        className="tw-text-gray-500 hover:tw-text-gray-700 dark:tw-text-gray-400 dark:hover:tw-text-gray-300"
                        onClick={toggleFullscreen}
                      >
                        <i className="bx bx-fullscreen tw-text-xl"></i>
                      </button>

                      <button
                        type="button"
                        className="tw-text-gray-500 hover:tw-text-gray-700 dark:tw-text-gray-400 dark:hover:tw-text-gray-300"
                        onClick={toggleDarkMode}
                      >
                        <i
                          className={`bx ${
                            isDarkMode ? "bx-sun" : "bx-moon"
                          } tw-text-xl`}
                        ></i>
                      </button>

                      <Menu as="div" className="tw-relative">
                        <Menu.Button
                          className={classNames(
                            "tw-flex tw-items-center tw-gap-3 tw-rounded-full tw-text-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2",
                            isDarkMode ? "tw-bg-gray-900" : "tw-bg-white",
                            isDarkMode ? "dark:tw-ring-offset-gray-900" : ""
                          )}
                        >
                          <span className="tw-sr-only">Open user menu</span>
                          <img
                            className="tw-h-8 tw-w-8 tw-rounded-full"
                            src={profileImg}
                            alt="Profile"
                          />
                          <div className="tw-hidden lg:tw-block">
                            <div
                              className={classNames(
                                "tw-font-medium",
                                isDarkMode
                                  ? "tw-text-white"
                                  : "tw-text-gray-900"
                              )}
                            >
                              {`${userData.first_name} ${userData.last_name}`.trim()}
                            </div>
                            <div
                              className={classNames(
                                "tw-text-xs",
                                isDarkMode
                                  ? "tw-text-gray-400"
                                  : "tw-text-gray-500"
                              )}
                            >
                              Tenant
                            </div>
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="tw-transition tw-ease-out tw-duration-100"
                          enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                          enterTo="tw-transform tw-opacity-100 tw-scale-100"
                          leave="tw-transition tw-ease-in tw-duration-75"
                          leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                          leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                        >
                          <Menu.Items
                            className={classNames(
                              "tw-absolute tw-right-0 tw-z-[1000] tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none",
                              isDarkMode ? "tw-bg-gray-900" : "tw-bg-white"
                            )}
                          >
                            <div
                              className={classNames(
                                "tw-px-4 tw-py-3 tw-border-b",
                                isDarkMode
                                  ? "tw-border-gray-700"
                                  : "tw-border-gray-200"
                              )}
                            >
                              <p
                                className={classNames(
                                  "tw-text-sm tw-truncate",
                                  isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                )}
                              >
                                {userData.email}
                              </p>
                            </div>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/logout"
                                  onClick={handleLogout}
                                  className={classNames(
                                    active
                                      ? isDarkMode
                                        ? "tw-bg-red-900/20"
                                        : "tw-bg-red-50"
                                      : "",
                                    "tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-red-600 dark:tw-text-red-400"
                                  )}
                                >
                                  Sign out
                                </Link>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {isAuthenticated && (
            <Disclosure.Panel
              className={classNames(
                "lg:tw-hidden tw-fixed tw-inset-y-0 tw-right-0 tw-z-[1001] tw-w-full tw-max-w-sm tw-shadow-lg tw-overflow-y-auto",
                isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
              )}
            >
              <div className="tw-h-16 tw-px-4 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
                <div className="tw-flex tw-items-center tw-gap-3">
                  <img
                    className="tw-h-8 tw-w-8 tw-rounded-full"
                    src={profileImg}
                    alt="Profile"
                  />
                  <div>
                    <div
                      className={classNames(
                        "tw-font-medium",
                        isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                      )}
                    >
                      {`${userData.first_name} ${userData.last_name}`.trim()}
                    </div>
                    <div
                      className={classNames(
                        "tw-text-sm",
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                      )}
                    >
                      {userData.email}
                    </div>
                  </div>
                </div>
                <Disclosure.Button className="tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-200 hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-500">
                  <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>

              <div className="tw-px-2 tw-py-3 tw-space-y-1">
                {navigation.map((item) =>
                  !item.items ? (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? isDarkMode
                            ? "tw-bg-gray-900 tw-text-blue-400"
                            : "tw-bg-indigo-50 tw-text-indigo-700"
                          : isDarkMode
                          ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                          : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <Disclosure
                      key={item.name}
                      as="div"
                      className="tw-space-y-1"
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              "tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium",
                              isDarkMode
                                ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                                : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
                            )}
                          >
                            {item.name}
                            <svg
                              className={classNames(
                                open ? "tw-rotate-180" : "",
                                "tw-h-5 tw-w-5 tw-transform tw-transition-transform"
                              )}
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="tw-space-y-1">
                            {item.items.map((subItem) => (
                              <Disclosure.Button
                                key={subItem.name}
                                as={Link}
                                to={subItem.href}
                                className={classNames(
                                  location.pathname === subItem.href
                                    ? isDarkMode
                                      ? "tw-bg-gray-900 tw-text-blue-400"
                                      : "tw-bg-indigo-50 tw-text-indigo-700"
                                    : isDarkMode
                                    ? "tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white"
                                    : "tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                                  "tw-block tw-rounded-md tw-py-2 tw-pl-6 tw-pr-3 tw-text-base tw-font-medium"
                                )}
                              >
                                {subItem.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </div>

              <div className="tw-border-t tw-border-gray-700">
                <div className="tw-px-4 tw-py-4 tw-space-y-4">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <button
                      type="button"
                      className={classNames(
                        "tw-flex tw-items-center tw-gap-2",
                        isDarkMode
                          ? "tw-text-gray-300 hover:tw-text-white"
                          : "tw-text-gray-600 hover:tw-text-gray-900"
                      )}
                      onClick={toggleDarkMode}
                    >
                      <i
                        className={`bx ${
                          isDarkMode ? "bx-sun" : "bx-moon"
                        } tw-text-xl`}
                      ></i>
                      <span className="tw-text-sm tw-font-medium">
                        {isDarkMode ? "Light Mode" : "Dark Mode"}
                      </span>
                    </button>
                  </div>

                  <div className="tw-space-y-1">
                    <Disclosure.Button
                      as={Link}
                      to="/logout"
                      onClick={handleLogout}
                      className={classNames(
                        isDarkMode
                          ? "tw-text-red-400 hover:tw-bg-red-900/20"
                          : "tw-text-red-600 hover:tw-bg-red-50",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;
