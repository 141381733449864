import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO, isSameDay, addDays, isAfter } from "date-fns";
import profileImg from "../../../styletheme/images/users/multi-user.jpg";
import {
  decryptData,
  generateKey,
  getPropertyUnitByPropertyUnitId,
  getTenantRentalApplicationByCampaignId,
  updateTenantProfileMainAppli,
} from "../../../services/tenant/masterApplication/tenantMainApplicationManagement";

import { getAllApplicationSnapshotsByTenant } from "../../../services/tenant/masterApplication/tenantMainApplicationManagement";
import config from "../../../constants/config.json";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { get } from "lodash";
import DashboardMiniFactCard from "./sub/dashboardMiniFactCard";
import { getCurrentTenantProfileByEmail } from "../../../services/authentication/tenantAuth";
import { useTenantProfileContext } from "../../../services/authentication/contexts/tenantProfile/currentTenantProfileContext";
import { toast } from "react-toastify";
import LoaderView from "../../reusable/loading/loaderView";
import { fetchPropertyAdvertById } from "../../../services/property/advertisement/propertyAdvertisement";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getAllAvailableViewingSlotsByCampaignId,
  getUnitViewingByCampaignIdAndTenantId,
  updateUnitViewing,
} from "../../../services/property/advertisement/unitViewing/unitViewingManagement";

//Modal
import { Modal, Badge, Button } from "react-bootstrap";
import {
  getOrCreateTenantUniversalList,
  updateTenantUniversalListItem,
} from "../../../services/tenant/tasks/tenantTaskListManagement";
import {
  getActionTypeText,
  getButtonBootstrapColor,
  getButtonNavigation,
  getStatusText,
} from "../../../services/utilis/actionTypeUtils";
import ShimmerEffect from "../../reusable/loading/shimmerEffect";
import { devLog } from "../../../utils/logger";
import { getApplicationStatusButtonText } from "../../../services/utilis/actionTypeUtils";
import {
  getLatestPendingRentalContractByTenant,
  getLatestRentalContract,
} from "../../../services/rental/contract/rentalContractService";
import { usePageTitle } from "../../../utils/hooks/usePageTitle";
import { formatCurrency } from "utils/currencyUtils";
import PageTitleBox from "components/reusable/title/pageTitleBox";
//TODO: now need to give ability to see and create tickets and see payments and receipts made by the tenant
//TODO: add the ability to see all the fees that need to be paid and the landlord verification of payment to start the lease

//TODO: need to show the current status of the tenant's application then work on the ability to submit the full application
//TODO: need to write test cases to test all the modes of this activity feed

//TODO: has_been_selected_for_the_rental, has_completed_application all true  and acceptance_date is not null, has lease started all true means the tenant has started the lease and now has access to receipts, tickets etc
const Dashboard = ({ isDarkMode }) => {
  //Also need to show the history of places the tenant has applied to
  usePageTitle({
    title: "Dashboard",
  });
  const [isLoading, setIsLoading] = useState();
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: "Relevant news about Gurenter and its offerings",
  });
  const { tenantProfile } = useTenantProfileContext();
  //add the viewing date and time
  //need to get the viewing date and time from the database
  //going to have to give availability to the tenant to select a date and time
  const [viewingTourDetails, setViewingTourDetails] = useState({
    viewingDate: "",
    viewingTime: "",
    location: "",
  });

  const [fullTenantProfile, setFullTenantProfile] = useState({
    tenantId: "",
    isTheMainTenant: false,
    hasCompletedApplicationSignUp: false,
    firstName: "",
    middleName: "",
    lastName: "",

    workPhoneNumber: "",
    dateOfBirth: new Date(),
    mainTenantImageUrl: "",
    emailAddress: "",
    //password: "",
    signatureDetail: [],
    currentApplPool: {
      landLordId: "",
      propertyId: "",
      contractId: "",
      inviteAccessCode: "",
      hasAccessCodeBeenUsed: false,
      isApprovedOrRejected: false,
      finalDecision: "",
      applicationStatus: "Not Started",
    },
    tenantApplication: {
      latestVersion: false,
      applicationLastUpdate: null,
      propertyId: "",
      propertyName: "",
      tenantApplicationSummaryData: {
        numberofOccupants: 0,
        numberOfReferenceAdded: 0,
        combinedSalary: 0,
      },
      rentCost: [],
      startDate: "",
      referredByDetail: [],
      applicantInfo: {
        firstName: "",
        middleInitial: "",
        lastName: "",
        trnSsn: "",
        driverLicense: "",
        dateOfBirth: "",
        homePhone: "",
        workPhone: "",
        emailAddress: "",
      },
      //separate the address into its own collection
      currentAddressDetail: {
        addressType: "",
        streetAddress: "",
        cityTown: "",
        parishState: "",
        countryName: "",
        zipPostalCode: "",
        dateIn: "",
        landLordName: "",
        landLordPhone: "",
        monthlyRent: "",
        recordTimeStamp: null,
      },

      preScreeningDetail: {
        emailAddress: "",
        firstName: "",
        lastName: "",
        durationOfCurrentResidence: 0,
        totalMonthlyIncome: 0,
        sourceOfIncome: "",
        numberOfOccupants: 0,
        reasonForLeaving: "",
        desiredMoveInDate: new Date(),
        doesAnyOneSmoke: false,
        hasPets: false,
        describePets: "",
      },
      previousAddressDetail: [],
      otherOccupants: [],
      petsDetail: [],
      employmentIncomeInfo: [],
      otherIncomeSources: [],
      emergencyContact: [],
      personalReferences: [],
      backgroundInfo: [],
      vehicleInfo: [],
    },
    recordCreatedDate: null,
    isPublic: false,
  });

  const [hasPassedInterview, setHasPassedInterview] = useState(false);
  //set the system set this (can set their own wording but not change the action)
  const [todoList, setTodoList] = useState([
    {
      taskName: "Schedule Viewing",
      action: "Schedule",
      type: "link",
      location:
        "https://calendly.com/03collis42/unit-30a-apartment-tour-jan-14-2024-clone", //'/opens the viewing picker and this only shows if the tenant is approved prescreening and viewing
      isDisabled: false,
    },
    {
      taskName: "Tour Unit",
      action: "Complete",
      type: "internal",
      location: "/completeUnitTour",
      isDisabled: true,
    },
    {
      taskName: "Sign Up Full Application",
      action: "Fill Out",
      type: "internal",
      location: "/primaryApplicationForm",
      isDisabled: false,
    },
    {
      taskName: "Interview",
      action: "Get Interview Link",
      type: "internal",
      location: "/getinterviewlink",
      isDisabled: true,
    },
    {
      taskName: "Sign Contract",
      action: "Sign",
      type: "internal",
      location: "/signcontract", //TODO: next step is to create the ability to sign the lease
      needToPassInterview: true, //shows only for those who passed the interview
      isDisabled: true,
    },
    {
      //give ability to enter the transaction number and or upload an image as proof
      taskName: "Pay Security Deposit and First Month's Rent",
      type: "internal",
      action: "Proof of Payment",
      needToPassInterview: true,
      isDisabled: true,
    },
  ]);

  const [allContracts, setAllContracts] = useState([
    {
      tenantId: "",
      isTheMainTenant: false,
      hasCompletedApplicationSignUp: false,
      firstName: "",
      middleName: "",
      lastName: "",

      workPhoneNumber: "",
      dateOfBirth: new Date(),
      mainTenantImageUrl: "",
      emailAddress: "",
      //password: "",
      signatureDetail: [],
      currentApplPool: {
        landLordId: "",
        propertyId: "",
        contractId: "",
        inviteAccessCode: "",
        hasAccessCodeBeenUsed: false,
        isApprovedOrRejected: false,
        finalDecision: "",
        applicationStatus: "Not Started",
      },
      tenantApplication: {
        latestVersion: false,
        applicationLastUpdate: null,
        propertyId: "",
        propertyName: "",
        tenantApplicationSummaryData: {
          numberofOccupants: 0,
          numberOfReferenceAdded: 0,
          combinedSalary: 0,
        },
        rentCost: [],
        startDate: "",
        referredByDetail: [],
        applicantInfo: {
          firstName: "",
          middleInitial: "",
          lastName: "",
          trnSsn: "",
          driverLicense: "",
          dateOfBirth: "",
          homePhone: "",
          workPhone: "",
          emailAddress: "",
        },
        //separate the address into its own collection
        currentAddressDetail: {
          addressType: "",
          streetAddress: "",
          cityTown: "",
          parishState: "",
          countryName: "",
          zipPostalCode: "",
          dateIn: "",
          landLordName: "",
          landLordPhone: "",
          monthlyRent: "",
          recordTimeStamp: null,
        },
        preScreeningDetail: {
          emailAddress: "",
          firstName: "",
          lastName: "",
          durationOfCurrentResidence: 0,
          totalMonthlyIncome: 0,
          sourceOfIncome: "",
          numberOfOccupants: 0,
          reasonForLeaving: "",
          desiredMoveInDate: new Date(),
          doesAnyOneSmoke: false,
          hasPets: false,
          describePets: "",
        },
        //make it into its own collection
        previousAddressDetail: [],
        otherOccupants: [],
        petsDetail: [],
        employmentIncomeInfo: [],
        otherIncomeSources: [],
        emergencyContact: [],
        personalReferences: [],
        backgroundInfo: [],
        vehicleInfo: [],
      },
      recordCreatedDate: null,
      isPublic: false,
      propertyUnitAdvertisementListing: {
        advertId: "",
        landlordId: "",
        propertyUnitId: "",
        dateAdvertEnds: "",
        campaignId: "",
        propertyUnit: {
          propertyUnitId: "",
          rentalPropertyId: "",
          unitName: "",
          rentReceiptSequence: 0,
          rentalUnitPrice: 0,
          rentalUnitAmtCurrency: "USD",
          unitType: "",
          unitCurrentRentalStatus: "Vacant",
          unitSpecifications: {
            numberOfBedrooms: 0,
            unitSquareFootage: 0,
            parkingDetail: {
              drivewayType: "", //paved, gravel, dirt
              parkingSpace: 0,
            },
            numberOfRooms: 0,
            numberOfBathrooms: 0,
            numberOfHalfBathroom: 0,

            hasKitchen: false,
            hasPatios: false,
            hasPorch: false,
            hasDiningRoom: false,
            hasHalfBathrooms: false,
            hasLivingRoom: false,
            hasCarGarage: false,
          },
          petSpecifications: {
            allowPets: false,
            maxNumberOfPetsAllowed: 0,
            petsAllowed: [
              {
                animalName: "",
                animalType: "",
                animalWeightMax: 0,
              },
            ],
          },
          dateLastRented: "",
          recordCreatedDate: "",
          isRented: false,
          isActivated: true,
        },
        unitAdvertImageUrls: [""],
        securityDeposit: 0,
        currencyId: "",
        currencyName: "",
        isAdvertExpired: false,
      },
    },
  ]);

  //this shows in the list of upcoming activities along with the other activities
  const [viewingSchedule, setViewingSchedule] = useState({
    unit_viewing_id: "",
    start_time: "",
    end_time: "",
    duration: "",
    campaign_id: "",
    status: "",
    tenant_id: "",
    tenant_name: "",
    note: "",
  });

  const [upcomingActivities, setUpcomingActivities] = useState([
    {
      activity_name: "",
      activity_type: "",
      activity_location: "",
      unit_viewing_id: "",
      tenant_id: "",
      tenant_name: "",
      note: "",
      status: "",
      start_time: "",
      end_time: "",
      duration: "",
      campaign_id: "",
      property_unit_id: "",
      property_id: "",
      landlord_id: "",
      note: "",
      record_created_date: "",
    },
  ]);

  //TODO: the co-occupants feature is needed to be implemented
  //set up the link code so the tenant can share with co-applicant to link the application
  //define the tenant rental application details properties later
  const [tenantRentalApplicationDetails, setTenantRentalApplicationDetails] =
    useState({
      tenant_rental_application_id: "",
      tenant_id: "",
      property_unit_id: "",
      campaign_id: "",
      co_applicant_link_code: "",
      application_status: "",
      application_date: "",
      has_passed_pre_screening: false,
      has_passed_viewing_screening: false,
      has_passed_application_screening: false,
      has_passed_application_screening_date: "",
      has_paid_application_fee: false,
      has_signed_lease: false,
      has_paid_first_month_rent: false,
      has_paid_last_month_rent: false,
      has_paid_security_deposit: false,
      needs_guarantor: false,
      has_passed_background_check: false,
      has_passed_credit_check: false,
      has_passed_eviction_check: false,
      has_passed_criminal_check: false,
      has_been_selected_for_viewing: false,
      has_been_selected_for_the_rental: false,
      co_applicant_connection: null,
      prescreening_info: {
        duration_of_current_residence: 0,
        total_monthly_income: 0,
        sources_of_income: [],
        number_of_occupants: 0,
        reason_for_leaving: "",
        desired_move_in_date: "",
        does_any_one_smoke: false,
        has_pets: false,
        describe_pets: "",
      },
      unit_viewing: null,
      //new
      rental_contract_id: "",
      has_all_parties_signed: false,
      is_in_payment_verification_step: false,
      has_paid_but_not_verified: false,
      has_lease_started: false,
      lease_started_at: Date(),
      has_lease_ended: false,
      lease_ended_at: Date(),
    });

  const [propertyUnitDetails, setPropertyUnitDetails] = useState({
    property_unit_id: "",
    property_id: "",
    unit_name: "",
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
  });

  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advert_id: "",
    landlord_id: "",
    property_unit_id: "",
    date_advert_ends: "",
    campaign_id: "",
    property_unit: {
      property_unit_id: "",
      property_id: "",
      market_rent_amount: 0,
      number_of_bathrooms: 0,
      number_of_bedrooms: 0,
      square_footage: 0,
      unit_name: "",
      //new
      number_of_half_bathrooms: "",
      number_of_rooms: "",
    },
    security_deposit: 0,
    screening_pack_id: "",
    currency_id: "",
    currency_name: "",
    is_advert_expired: false,
    rental_unit_price: 0,
    rental_unit_amt_currency: "",
    unit_advert_image_urls: [],
  });

  //TODO: screen which shows all past contracts, current contracts, and future contracts (not started as yet)
  //rental contract
  const [latestRentalContract, setLatestRentalContract] = useState({
    rental_contract_id: "",
    tenant_id: "",
    property_unit_id: "",
    start_date: "",
    end_date: "",
    monthly_rent: 0,
    security_deposit: 0,
    contract_template_id: "",
    contract_detail: "",
    portfolio_ownership_id: "",
    property_name: "",
    property_unit_name: "",
    portfolio_name: "",
    owner_manager_user_id: "",
    owner_manager_name: "",
    owner_manager_email: "",
    end_of_lease_action: "",
    is_active: true,
    is_deleted: false,
    rental_fee_pack_template_id: "",
    rental_fee_pack_id: "",
    screening_pack_id: "",
    campaign_id: "",
    //Need to prefill who needs to sign the contract
    landlord_property_manager_signatures: [
      //TODO: next saving the signature for the logged in user and disable the others for the logged in user
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "", //aws s3 url
        signature_base64: "", //base64 string (not for storage )
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_id: "",
        user_type: "",
        landlord_id: "",
        email_address: "",
      },
    ],
    tenant_signatures: [
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "",
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_id: "",
        user_type: "",
        tenant_id: "",
        email_address: "",
      },
    ],
  });

  const [isCopied, setIsCopied] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  //show available viewing slots view modal
  const [showViewingModal, setShowViewingModal] = useState(false);
  const handleCloseViewingModal = () => setShowViewingModal(false);
  const [slots, setSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);

  const findNextAvailableDate = (slotsData) => {
    const availableSlot = slotsData.find((slot) => slot.status === "available");
    if (availableSlot) {
      setCurrentDate(parseISO(availableSlot.start_time));
    }
  };
  let availableSlotsForCurrentDate = [];

  if (Array.isArray(slots) && slots.length > 0) {
    availableSlotsForCurrentDate = slots.filter(
      (slot) =>
        isSameDay(parseISO(slot.start_time), currentDate) &&
        slot.status === "available"
    );
  }

  const handleDateChange = (increment) => {
    setCurrentDate((prevDate) => addDays(prevDate, increment));
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
    // Here you would typically call an API to update the slot's status
  };

  const submitSlotSelectionToServer = async () => {
    // Here you would typically call an API to update the slot's status
    //add the user id and name to the selected slot

    //when deselecting a slot, set the user_id and tenant_name to null
    selectedSlot.tenant_id = tenantProfile.tenant_id;
    selectedSlot.tenant_name =
      tenantProfile.first_name + " " + tenantProfile.last_name;
    //automated note
    selectedSlot.note =
      "Automated note: Slot selected by " +
      tenantProfile.first_name +
      " " +
      tenantProfile.last_name;
    //status
    selectedSlot.status = "booked";
    //console.log("Selected slot:", selectedSlot);

    const response = await updateUnitViewing(selectedSlot);
    //console.log("Response:", response);

    if (response) {
      setSelectedSlot(null);
      setShowViewingModal(false);
      fetchAvailableUnitViewingSlots(); // Refresh slots after successful update

      //TODO: need to update the upcoming activities (the tenant can cancel their own slot there in the upcoming activities)
      //TODO: need to also send an email to the tenant with the selected slot details and notify the landlord/property manager
    }
  };

  //TODO: Need to test this out
  const cancelSlotSelection = async () => {
    // Here you would typically call an API to update the slot's status
    //add the user id and name to the selected slot

    //when deselecting a slot, set the user_id and tenant_name to null
    selectedSlot.tenant_id = "";
    selectedSlot.tenant_name = "";
    //automated note
    selectedSlot.note = "";
    //status
    selectedSlot.status = "available";
    //console.log("Selected slot:", selectedSlot);

    //cancel the current slot selection and update the database
    const response = await updateUnitViewing(selectedSlot);
    //console.log("Response:", response);

    if (response) {
      setSelectedSlot(null);
      fetchAvailableUnitViewingSlots(); // Refresh slots after successful update
    }

    setSelectedSlot(null);
  };

  const fetchAvailableUnitViewingSlots = async (_campaignId) => {
    setIsLoading(true);
    try {
      // This is a mock API call. Replace with your actual API call.
      const response = await getAllAvailableViewingSlotsByCampaignId(
        _campaignId
      );
      if (response && Array.isArray(response)) {
        //console.log(response);
        setSlots(response);
        findNextAvailableDate(response);
      } else {
        console.error("Invalid response format:", response);
        setSlots([]); // Ensure slots is an array
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      setSlots([]); // Ensure slots is an array in case of error
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  //viewingSchedule
  const fetchMyViewingDateTime = async (_campaign_id, _tenant_id) => {
    const response = await getUnitViewingByCampaignIdAndTenantId(
      _campaign_id,
      _tenant_id
    );
    if (response) {
      //console.log("My Viewing Schedule");
      //console.log(response);
      setViewingSchedule(response);
      return response;
    }
  };

  const [tenantUniversalList, setTenantUniversalList] = useState({
    tenant_universal_list: {
      _id: "",
      universal_list_id: "",
      original_universal_list_id: "",
      tenant_id: "",
      campaign_id: "",
      name: "",
      description: "",
      created_at: null,
      updated_at: null,
    },
    tenant_universal_list_items: [
      {
        universal_list_item_id: "",
        universal_list_id: "",
        name: "",
        description: "",
        is_completed: false,
        created_at: null,
        updated_at: null,
      },
    ],
  });

  //get the tenant universal list
  const fetchTenantUniversalList = async (_tenant_id, _campaign_id) => {
    //console.log("Fetch Tenant Universal List");
    //console.log("Tenant ID", _tenant_id);
    //console.log("Campaign ID", _campaign_id);
    const response = await getOrCreateTenantUniversalList(
      _tenant_id,
      _campaign_id
    );
    if (response) {
      devLog("Tenant Universal List", response);

      setTenantUniversalList(response);
    }
  };

  const [isTodoListLoading, setIsTodoListLoading] = useState(false);

  const refreshTodoList = async () => {
    if (tenantProfile && tenantProfile.tenant_id && tenantProfile.campaign_id) {
      try {
        setIsTodoListLoading(true);
        const response = await getOrCreateTenantUniversalList(
          tenantProfile.tenant_id,
          tenantProfile.campaign_id
        );
        if (response) {
          //console.log("Refreshed Tenant Universal List", response);
          setTenantUniversalList(response);
          toast.success("Todo list refreshed successfully");
        }
      } catch (error) {
        //console.error("Error refreshing todo list:", error);
        toast.error("Failed to refresh todo list. Please try again.");
      } finally {
        setIsTodoListLoading(false);
      }
    } else {
      // console.error("Tenant profile, tenant ID, or campaign ID is missing");
      toast.error("Unable to refresh todo list. Missing tenant information.");
    }
  };

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
  const [isApplicationStatusLoading, setIsApplicationStatusLoading] =
    useState(true);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    //set the other calls

    // Cleanup function to clear the timeout if the component unmounts or if isLoading is set to false before the timeout
    return () => clearTimeout(loadingTimeout);
  }, []);

  // Set a timeout to ensure isLoading becomes false after 5 seconds (adjust as needed)
  const loadingTimeout = setTimeout(() => {
    setIsLoading(false);
  }, 5000);

  useEffect(() => {
    const fetchData = async () => {
      if (tenantProfile && tenantProfile.first_name) {
        setIsLoading(true);
        try {
          setIsProfileLoading(true);
          setIsActivitiesLoading(true);
          setIsTodoListLoading(true);
          setIsApplicationStatusLoading(true);

          await fetchTenantRentalApplicationDetails(tenantProfile.campaign_id);
          const property_advert = await fetchAdvertisementListing();
          await fetchAvailableUnitViewingSlots(tenantProfile.campaign_id);
          await compileUpcomingActivities(
            tenantProfile.campaign_id,
            tenantProfile.tenant_id
          );

          await fetchLatestPendingRentalContract(
            tenantProfile.tenant_id,
            tenantProfile.campaign_id,
            property_advert.property_unit_id
          );

          setIsProfileLoading(false);
          setIsActivitiesLoading(false);
          setIsTodoListLoading(false);
          setIsApplicationStatusLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle errors appropriately
        } finally {
          setIsLoading(false);
        }
      } else {
        //console.log("Tenant profile is not set.");
      }
    };

    fetchData();
  }, [tenantProfile]);

  const transformViewingData = (viewingData) => {
    if (!viewingData || !viewingData.unit_viewing_id) {
      return null; // Return null for invalid data
    }

    return {
      activity_name: "Unit Viewing",
      activity_type: "Viewing",
      activity_location: viewingData.location || "Property Location",
      unit_viewing_id: viewingData.unit_viewing_id,
      tenant_id: viewingData.tenant_id,
      tenant_name: viewingData.tenant_name,
      note: viewingData.note,
      status: viewingData.status,
      start_time: viewingData.start_time,
      end_time: viewingData.end_time,
      duration: viewingData.duration,
      campaign_id: viewingData.campaign_id,
      property_unit_id: viewingData.property_unit_id || "",
      property_id: viewingData.property_id || "",
      landlord_id: viewingData.landlord_id || "",
      record_created_date: viewingData.created_at,
      is_expired: viewingData.is_expired === "true",
      is_cancelled: viewingData.is_cancelled === "true",
    };
  };

  const updateUpcomingActivities = (currentActivities, newViewingData) => {
    if (!newViewingData || !newViewingData.unit_viewing_id) {
      return currentActivities; // Return current activities if new data is invalid
    }

    const transformedViewingData = transformViewingData(newViewingData);

    const updatedActivities = currentActivities.filter(
      (activity) =>
        activity.unit_viewing_id !== transformedViewingData.unit_viewing_id
    );

    updatedActivities.push(transformedViewingData);

    return updatedActivities;
  };

  //compile the upcoming activities
  const compileUpcomingActivities = async (_campaign_id, _tenant_id) => {
    try {
      // Fetch the viewing data
      const viewingData = await fetchMyViewingDateTime(
        _campaign_id,
        _tenant_id
      );

      // Fetch the tenant universal list
      await fetchTenantUniversalList(_tenant_id, _campaign_id);

      // Update activities with viewing data if it exists and is not expired or cancelled
      if (viewingData && viewingData.unit_viewing_id) {
        const isExpired =
          viewingData.is_expired === true || viewingData.is_expired === "true";
        const isCancelled =
          viewingData.is_cancelled === true ||
          viewingData.is_cancelled === "true";

        if (!isExpired && !isCancelled) {
          setUpcomingActivities((prevActivities) => {
            const updatedActivities = updateUpcomingActivities(
              prevActivities,
              viewingData
            );
            //console.log("Updated Upcoming Activities", updatedActivities);
            return updatedActivities;
          });
        } else {
          //console.log("Viewing is expired or cancelled", viewingData);
        }
      } else {
        console.log("No valid viewing data found");
      }

      // If you have other activities to add, you can do so here
      // const otherActivities = await fetchOtherActivities();
      // setUpcomingActivities(prevActivities => [...prevActivities, ...otherActivities]);
    } catch (error) {
      console.error("Error compiling upcoming activities:", error);
      // Handle the error appropriately
    }
  };

  //get the tenant rental application details
  const fetchTenantRentalApplicationDetails = async (_campaign_id) => {
    //get the tenant rental application details

    const { data: tenantRentalApplicationDetails } =
      await getTenantRentalApplicationByCampaignId(_campaign_id);
    //console.log(tenantRentalApplicationDetails);
    if (tenantRentalApplicationDetails) {
      //show console log in development environment
      devLog(
        "Tenant Rental Application Details",
        tenantRentalApplicationDetails
      );
      setTenantRentalApplicationDetails(tenantRentalApplicationDetails);
      if (tenantRentalApplicationDetails.property_unit_id) {
        fetchPropertyUnitDetails(
          tenantRentalApplicationDetails.property_unit_id
        );
      }
    }
  };

  //TODO: need to come up with a design to show the current pending application (campaignId)
  const fetchPropertyUnitDetails = async (_propertyUnitId) => {
    const { data: propertyUnitDetails } = await getPropertyUnitByPropertyUnitId(
      _propertyUnitId
    );
    if (propertyUnitDetails) {
      //console.log(propertyUnitDetails);
      setPropertyUnitDetails(propertyUnitDetails);
    }
  };

  //Need a more robust way to get the latest rental contract and set it
  //get the latest rental contract
  const fetchLatestPendingRentalContract = async (
    _tenant_id,
    _campaign_id,
    _property_unit_id
  ) => {
    const response = await getLatestPendingRentalContractByTenant(
      _tenant_id,
      _campaign_id,
      _property_unit_id
    );
    if (response) {
      devLog("Latest Rental Contract", response);
      setLatestRentalContract(response);
      if (response.rental_contract_id) {
        localStorage.setItem(
          config.rentalContractId,
          response.rental_contract_id
        );
      }
    } else {
      devLog("No latest rental contract found");

      //it means we should as a fall back check getLatestRentalContract
      const response = await getLatestRentalContract(
        _tenant_id,
        _campaign_id,
        _property_unit_id
      );
      devLog("Latest Rental Contract Fallback", response);
      setLatestRentalContract(response);

      if (response.rental_contract_id) {
        localStorage.setItem(
          config.rentalContractId,
          response.rental_contract_id
        );
      }
    }
  };

  //get all contracts
  //getAllApplicationSnapshotsByTenant
  const getAllApplicationSnapshots = async (tenantId) => {
    //get all the contracts
    const { data: allContracts } = await getAllApplicationSnapshotsByTenant(
      tenantId
    );

    //console.log(allContracts);

    setAllContracts(allContracts);
    //setAllContracts
  };

  //get the advertisement listing
  const fetchAdvertisementListing = async () => {
    const property_advert = await fetchPropertyAdvertById(
      tenantProfile.campaign_id
    );
    if (property_advert) {
      //console.log(property_advert);
      devLog("Property Advert", property_advert);
      setPropertyUnitAdvert(property_advert);

      return property_advert;
    }
  };

  const renderActionButton = (item) => {
    const ButtonComponent = item.type === "link" ? "a" : Link;
    const buttonProps =
      item.type === "link"
        ? { href: item.location, target: "_blank", rel: "noopener noreferrer" }
        : { to: item.isDisabled ? "#" : item.location };

    return (
      <ButtonComponent
        {...buttonProps}
        className={`btn btn-sm ${
          item.isDisabled ? "btn-light" : "btn-soft-primary"
        }`}
        onClick={(e) => {
          if (
            item.isDisabled ||
            (!hasPassedInterview && item.needToPassInterview)
          ) {
            e.preventDefault();
          }
        }}
      >
        <i
          className={`${
            item.type === "link"
              ? "ri-external-link-line"
              : "ri-arrow-right-line"
          } me-1 align-bottom`}
        ></i>
        {item.action}
      </ButtonComponent>
    );
  };

  // Add this function outside of your component
  const getApplicationStatus = (details) => {
    if (!details.has_passed_pre_screening) {
      return "NOT_PRESCREENED";
    }
    if (!details.has_been_selected_for_viewing) {
      return "UNDER_REVIEW";
    }
    if (!details.has_been_selected_for_the_rental) {
      return "SELECTED_FOR_VIEWING";
    }
    return "SELECTED_FOR_RENTAL";
  };

  // Add this helper function to filter activities
  const filterCurrentAndFutureActivities = (activities) => {
    const now = new Date();
    return activities.filter((activity) =>
      isAfter(parseISO(activity.end_time), now)
    );
  };

  // Inside your component, replace the existing content with this:
  const renderApplicationStatus = () => {
    if (!tenantRentalApplicationDetails) return null;

    const status = getApplicationStatus(tenantRentalApplicationDetails);
    const currentAndFutureActivities =
      filterCurrentAndFutureActivities(upcomingActivities);

    const renderActivities = () => {
      return currentAndFutureActivities.length > 0
        ? currentAndFutureActivities.map((activity, index) => (
            <div key={index} className="col-xxl-12">
              <ul className="list-group list-group-flush border-dashed">
                <li className="list-group-item ps-0 card">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                        <div className="text-center">
                          <h5 className="mb-0">
                            {format(parseISO(activity.start_time), "d")}
                          </h5>
                          <div className="text-muted">
                            {format(parseISO(activity.start_time), "EEE")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-start">
                        <h5 className="text-muted mt-0 mb-1 fs-13">
                          {format(parseISO(activity.start_time), "h:mm a")} -{" "}
                          {format(parseISO(activity.end_time), "h:mm a")}
                        </h5>
                        <a href="#" className="text-reset fs-14 mb-0">
                          {activity.activity_name}
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))
        : null;
    };

    const renderViewingSelection = () => (
      <>
        <p>You have been selected for a viewing.</p>
        <p>Please click the button below to select a viewing date and time.</p>
        <Link
          className="btn btn-primary btn-sm"
          onClick={() => setShowViewingModal(true)}
        >
          Select Viewing Date
        </Link>
      </>
    );

    if (
      tenantRentalApplicationDetails.has_passed_pre_screening &&
      tenantRentalApplicationDetails.has_been_selected_for_viewing &&
      !tenantRentalApplicationDetails.has_been_selected_for_the_rental &&
      !tenantRentalApplicationDetails.has_been_hard_rejected
    ) {
      const activities = renderActivities();
      return <>{activities ? activities : renderViewingSelection()}</>;
    }

    switch (status) {
      case "NOT_PRESCREENED":
        return (
          <>
            <p>No upcoming activities</p>
            <p>
              Once you are approved for pre-screening, you will be able to set
              and view your viewing schedule here.
            </p>
            {/*Put a lottie animation here*/}
          </>
        );
      case "UNDER_REVIEW":
        return (
          <>
            <p>
              Your application is under review. Please check back later for
              updates.
            </p>
            {renderActivities() || <p>No upcoming activities at the moment.</p>}
          </>
        );
      case "SELECTED_FOR_VIEWING":
        return renderViewingSelection();
      case "SELECTED_FOR_RENTAL":
        return (
          renderActivities() || <p>No upcoming activities at the moment.</p>
        );
      default:
        return (
          <>
            <p>
              Your application status is being processed. Please check back
              later.
            </p>
            {renderActivities() || <p>No upcoming activities at the moment.</p>}
          </>
        );
    }
  };

  const handleTaskCompletion = async (listItemId, isCompleted) => {
    try {
      const updatedItem = await updateTenantUniversalListItem(
        listItemId,
        isCompleted
      );
      if (updatedItem) {
        setTenantUniversalList((prevState) => ({
          ...prevState,
          tenant_universal_list_items:
            prevState.tenant_universal_list_items.map((item) =>
              item.universal_list_item_id === listItemId
                ? { ...item, is_completed: isCompleted }
                : item
            ),
        }));
        toast.success("Task status updated successfully");
      }
    } catch (error) {
      console.error("Error updating task completion status:", error);
      toast.error("Failed to update task status. Please try again.");
    }
  };

  const getStatusColor = (status, hasBeenHardRejected) => {
    if (hasBeenHardRejected || status.toLowerCase() === "rejected") {
      return "danger";
    } else if (
      status.toLowerCase().includes("passed") ||
      status.toLowerCase() === "approved"
    ) {
      return "success";
    } else if (
      status.toLowerCase() === "pending" ||
      status.toLowerCase().includes("in progress")
    ) {
      return "warning";
    } else {
      return "info";
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      <Modal show={showViewingModal} onHide={handleCloseViewingModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Available Viewing Slots</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gy-4">
            <div className="col-xxl-12 col-md-12">
              <div>
                <div className="container mt-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h2 className="card-title mb-4">Select a Time Slot</h2>
                      <div className="row mb-4 align-items-center">
                        <div className="col-md-4 col-6">
                          <button
                            className="btn btn-outline-primary w-100"
                            onClick={() => handleDateChange(-1)}
                          >
                            Previous Day
                          </button>
                        </div>
                        <div className="col-md-4 col-12 text-center my-2 my-md-0">
                          <h3 className="mb-0">
                            {format(currentDate, "MMMM d, yyyy")}
                          </h3>
                        </div>
                        <div className="col-md-4 col-6">
                          <button
                            className="btn btn-outline-primary w-100"
                            onClick={() => handleDateChange(1)}
                          >
                            Next Day
                          </button>
                        </div>
                      </div>
                      {availableSlotsForCurrentDate.length > 0 ? (
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                          {availableSlotsForCurrentDate.map((slot) => (
                            <div key={slot.start_time} className="col">
                              <button
                                onClick={() => handleSlotSelect(slot)}
                                className={`btn btn-outline-secondary w-100 ${
                                  selectedSlot === slot ? "active" : ""
                                }`}
                              >
                                {format(parseISO(slot.start_time), "h:mm a")} -{" "}
                                {format(parseISO(slot.end_time), "h:mm a")}
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="alert alert-info" role="alert">
                          No available slots for this date. Please try another
                          day.
                        </div>
                      )}
                      {selectedSlot && (
                        <div className="mt-4 p-3 bg-light rounded">
                          <h4>Selected Slot:</h4>
                          <p className="mb-2">
                            {format(
                              parseISO(selectedSlot.start_time),
                              "MMMM d, yyyy h:mm a"
                            )}{" "}
                            -{" "}
                            {format(parseISO(selectedSlot.end_time), "h:mm a")}
                          </p>
                          <button
                            className="btn btn-danger"
                            onClick={() => cancelSlotSelection()}
                          >
                            Cancel Selection
                          </button>

                          <button
                            className="btn btn-success ms-2"
                            onClick={submitSlotSelectionToServer}
                          >
                            Confirm Selection
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewingModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          {/* start page title */}

          <PageTitleBox
            pageTitle="Renter's Dashboard"
            previousPageTitle="Dashboard"
            previousPageLink="/"
            isDarkMode={isDarkMode}
          />
          {/* end page title */}

          <div className="row">
            <div className="col-xxl-5">
              <div className="d-flex flex-column h-100">
                <div className="row h-100">
                  <div className="col-xl-4">
                    <div className="card">
                      {isProfileLoading ? (
                        <ShimmerEffect type="card" height="200px" />
                      ) : (
                        <div className="card-body p-4 text-center">
                          <div className="mx-auto avatar-md mb-3">
                            <img
                              src={profileImg}
                              alt=""
                              style={{ width: "75px", height: "75px" }}
                              className="img-fluid rounded-circle"
                            />
                          </div>
                          <h5 className="card-title mb-1">
                            {tenantProfile.first_name} {tenantProfile.last_name}
                          </h5>
                          <p className="text-muted mb-0">
                            {tenantProfile.email_address}
                          </p>
                        </div>
                      )}
                      <div className="card-footer text-center">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            {/* Fill out the full application*/}
                            <Link
                              to={`/primaryApplicationForm/${tenantProfile.campaign_id}`}
                            >
                              <button className="btn btn-primary btn-sm">
                                {getApplicationStatusButtonText(
                                  tenantRentalApplicationDetails.application_status
                                )}
                              </button>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* end col*/}

                  {/*This is where the showing will appear and a button to select a viewing */}
                  <div className="col-xxl-7">
                    <div className="card" style={{ height: "100%" }}>
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Upcoming Activities
                        </h4>
                        <div className="flex-shrink-0">
                          <div hidden className="dropdown card-header-dropdown">
                            <a
                              className="text-reset dropdown-btn"
                              href="#"
                              onClick={toggleDropdown}
                              aria-haspopup="true"
                              aria-expanded={isDropdownOpen}
                            >
                              <span className="text-muted fs-18">
                                <i className="mdi mdi-dots-vertical"></i>
                              </span>
                            </a>
                            <div
                              className={`dropdown-menu dropdown-menu-end${
                                isDropdownOpen ? " show" : ""
                              }`}
                            >
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                Edit
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end card header */}
                      {/* May have to be a switch statement */}
                      {/* Upcoming activities */}
                      <div className="card-body pt-0">
                        {isActivitiesLoading ? (
                          <ShimmerEffect type="card" height="150px" />
                        ) : (
                          <ul className="list-group list-group-flush border-dashed">
                            <li className="list-group-item ps-0">
                              <div className="text-center">
                                {renderApplicationStatus()}
                              </div>
                            </li>
                          </ul>
                        )}
                        {/* end */}
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                </div>
                {/* end row*/}

                {/* end row*/}
              </div>
            </div>
            {/* end col*/}

            <div className="col-xxl-6">
              <div className="row h-100">
                <div className="col-12">
                  <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        My Todo List
                      </h4>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          className="btn btn-soft-primary btn-sm"
                          onClick={refreshTodoList}
                          disabled={isTodoListLoading}
                        >
                          {isTodoListLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Refreshing...
                            </>
                          ) : (
                            <>
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              Refresh
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      {isTodoListLoading ? (
                        <ShimmerEffect type="card" height="200px" />
                      ) : (
                        <div className="todo-task">
                          <div className="table-responsive">
                            <table className="table table-nowrap align-middle mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Task</th>
                                  <th scope="col">Description</th>
                                  <th scope="col" style={{ width: "150px" }}>
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {tenantUniversalList.tenant_universal_list_items
                                  .filter((item) => !item.is_completed)
                                  .map((item, index) => (
                                    <tr key={item.universal_list_item_id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-3">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`todo${item.universal_list_item_id}`}
                                              checked={item.is_completed}
                                              onChange={(e) =>
                                                handleTaskCompletion(
                                                  item.universal_list_item_id,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="flex-grow-1">
                                            <label
                                              className={`form-check-label ${
                                                item.is_completed
                                                  ? "text-muted text-decoration-line-through"
                                                  : ""
                                              }`}
                                              htmlFor={`todo${item.universal_list_item_id}`}
                                            >
                                              {item.name}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            item.is_completed
                                              ? "text-muted text-decoration-line-through"
                                              : ""
                                          }
                                        >
                                          {item.description}
                                        </span>
                                      </td>
                                      <td>
                                        {item.action_type === "none" ||
                                        item.action_type === "utility_setup" ||
                                        item.action_type === "key_collection" ||
                                        item.action_type ===
                                          "schedule_move_in" ||
                                        item.action_type === "inspection" ? (
                                          <span className="badge bg-light text-muted">
                                            No action required in the App.
                                          </span>
                                        ) : item.action_type ? (
                                          <button
                                            className="btn btn-sm btn-soft-primary"
                                            onClick={() => {
                                              // Add action logic here based on action_type
                                              //console.log(
                                              //  `Action for ${item.name}: ${item.action_type}`
                                              //);
                                              // Implement specific logic for each action_type
                                              switch (item.action_type) {
                                                case "schedule_viewing":
                                                  // Logic to open scheduling modal or navigate to scheduling page
                                                  break;
                                                // Add more cases for other action types
                                                case "utility_setup":
                                                  // Logic to open utility setup modal or navigate to utility setup page
                                                  break;
                                                case "key_collection":
                                                  // Logic to open key collection modal or navigate to key collection page
                                                  break;
                                                case "schedule_move_in":
                                                  // Logic to open move in scheduling modal or navigate to move in scheduling page
                                                  break;
                                                default:
                                                  console.log(
                                                    "No specific action defined for this type"
                                                  );
                                              }
                                            }}
                                            disabled={item.is_completed}
                                            title={getActionTypeText(
                                              item.action_type
                                            )}
                                          >
                                            {getActionTypeText(
                                              item.action_type
                                            )}
                                          </button>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end col */}

            <div className="row mt-4">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">
                      Current Application Status
                    </h4>
                  </div>
                  <div className="card-body">
                    {isApplicationStatusLoading ? (
                      <ShimmerEffect type="card" height="250px" />
                    ) : (
                      tenantRentalApplicationDetails &&
                      propertyUnitAdvert && (
                        <div className="mb-4">
                          <div className="d-flex flex-column h-100">
                            <div
                              className={`p-3 bg-${getStatusColor(
                                tenantRentalApplicationDetails.application_status,
                                tenantRentalApplicationDetails.has_been_hard_rejected
                              )}-subtle rounded mb-3`}
                            >
                              <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <h5 className="mb-2 mb-sm-0 fs-16 ">
                                  Application Status
                                </h5>
                                <span
                                  className={`badge bg-${getStatusColor(
                                    tenantRentalApplicationDetails.application_status,
                                    tenantRentalApplicationDetails.has_been_hard_rejected
                                  )}`}
                                >
                                  {getStatusText(
                                    tenantRentalApplicationDetails.application_status
                                  )}
                                </span>
                              </div>
                            </div>
                            {tenantRentalApplicationDetails.has_been_hard_rejected && (
                              <div className="alert alert-danger" role="alert">
                                Your application has been rejected. If you have
                                any questions, please contact the property
                                manager.
                              </div>
                            )}
                            <div className="d-flex flex-column flex-md-row mt-3">
                              <div className="flex-shrink-0 me-md-3 mb-3 mb-md-0">
                                <div className="avatar-xl">
                                  <span className="avatar-title bg-warning-subtle rounded p-2">
                                    {Array.isArray(
                                      propertyUnitAdvert.unit_advert_image_urls
                                    ) &&
                                    propertyUnitAdvert.unit_advert_image_urls
                                      .length > 0 ? (
                                      <img
                                        src={
                                          propertyUnitAdvert
                                            .unit_advert_image_urls[0].url
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src="assets/images/brands/slack.png"
                                        alt=""
                                        className="img-fluid p-1"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h2 className="mb-1">
                                  {propertyUnitAdvert.property_unit.unit_name}
                                </h2>
                                <div className="d-flex align-items-center mb-3">
                                  <p className="text-muted mb-0 me-2">
                                    Co-applicant Link Code:
                                  </p>
                                  <code>
                                    {
                                      tenantRentalApplicationDetails.co_applicant_link_code
                                    }
                                  </code>
                                  <CopyToClipboard
                                    text={
                                      tenantRentalApplicationDetails.co_applicant_link_code
                                    }
                                    onCopy={handleCopy}
                                  >
                                    <button
                                      className="btn btn-xs btn-outline-primary ms-2 py-0 px-1"
                                      style={{ fontSize: "0.75rem" }}
                                    >
                                      {isCopied ? "Copied!" : "Copy"}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                <div className="d-flex flex-wrap gap-2 gap-md-3 mb-3">
                                  <DashboardMiniFactCard
                                    imgUrl={"las la-ruler-combined la-2x"}
                                    label={"Square Footage"}
                                    value={
                                      propertyUnitAdvert.property_unit
                                        .square_footage
                                    }
                                    valueLabel={"sqft"}
                                  />
                                  <DashboardMiniFactCard
                                    imgUrl={"las la-bed la-2x"}
                                    label={"Bedrooms"}
                                    value={
                                      propertyUnitAdvert.property_unit
                                        .number_of_bedrooms
                                    }
                                  />
                                  <DashboardMiniFactCard
                                    imgUrl={"las la-toilet la-2x"}
                                    label={"Bathrooms"}
                                    value={
                                      propertyUnitAdvert.property_unit
                                        .number_of_bathrooms
                                    }
                                  />
                                </div>
                                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                                  <h5 className="mb-2 mb-sm-0">
                                    <i className="ri-money-dollar-circle-line me-1 align-bottom"></i>
                                    {formatCurrency(
                                      propertyUnitAdvert.rental_unit_price,
                                      propertyUnitAdvert.rental_unit_amt_currency
                                    )}
                                    / month
                                  </h5>
                                  <Link
                                    className={`btn btn-${getButtonBootstrapColor(
                                      tenantRentalApplicationDetails.application_status
                                    )}`}
                                    disabled={
                                      getButtonNavigation(
                                        tenantRentalApplicationDetails.application_status,
                                        tenantRentalApplicationDetails.campaign_id,
                                        latestRentalContract.rental_contract_id
                                      ).disabled
                                    }
                                    // to={`/primaryApplicationForm/${tenantRentalApplicationDetails.campaign_id}`}
                                    to={
                                      getButtonNavigation(
                                        tenantRentalApplicationDetails.application_status,
                                        tenantRentalApplicationDetails.campaign_id,
                                        latestRentalContract.rental_contract_id
                                      ).path
                                    }
                                  >
                                    {getApplicationStatusButtonText(
                                      tenantRentalApplicationDetails.application_status
                                    )}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
