import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTenantPayments } from "../../../../services/payment/tenantPaymentService";
import { TenantPaymentDetailsResponse } from "../../../../services/payment/types";
import { formatCurrency } from "../../../../utils/currencyUtils";
import { format } from "date-fns";
import {
  Loader2,
  ChevronLeft,
  ChevronRight,
  Receipt,
  Eye,
  ExternalLink,
} from "lucide-react";
import { devLog } from "utils/logger";
import PageTitleBox from "components/reusable/title/pageTitleBox";
import { usePageTitle } from "../../../../utils/hooks/usePageTitle";

interface PaymentHistoryProps {
  isDarkMode: boolean;
}

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({
  isDarkMode,
}) => {
  usePageTitle({
    title: "Payment History",
  });
  const { rentalContractId } = useParams<{ rentalContractId: string }>();
  const [payments, setPayments] = useState<TenantPaymentDetailsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPayments, setTotalPayments] = useState(0);
  const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!rentalContractId) return;
    fetchPayments(currentPage);
  }, [rentalContractId, currentPage]);

  const fetchPayments = async (page: number) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getTenantPayments(
        rentalContractId!,
        page,
        itemsPerPage
      );
      setPayments(response.payments);
      setTotalPayments(response.total_count);
      setTotalPages(Math.ceil(response.total_count / itemsPerPage));

      devLog("Payments", response);
    } catch (err: any) {
      console.error("Error fetching payments:", err);
      setError(err.message || "Failed to load payment history");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "succeeded":
        return isDarkMode ? "tw-text-green-400" : "tw-text-green-600";
      case "failed":
        return isDarkMode ? "tw-text-red-400" : "tw-text-red-600";
      case "processing":
        return isDarkMode ? "tw-text-blue-400" : "tw-text-blue-600";
      default:
        return isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600";
    }
  };

  return (
    <div className="tw-w-full tw-px-4 ">
      <PageTitleBox
        pageTitle="Payment History"
        previousPageTitle="Payment Management"
        previousPageLink={`/paymentManagement/dashboard/${rentalContractId}`}
        isDarkMode={isDarkMode}
      />
      <div className="tw-w-full">
        {error && (
          <div className="tw-rounded-lg tw-bg-red-50 tw-p-4 tw-text-red-500 tw-mb-6">
            {error}
          </div>
        )}

        {isLoading ? (
          <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
            <Loader2 className="tw-w-8 tw-h-8 tw-animate-spin" />
          </div>
        ) : payments.length === 0 ? (
          <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
            <p className="tw-text-gray-500">No payment history available</p>
          </div>
        ) : (
          <>
            <div
              className={`tw-bg-${
                isDarkMode ? "gray-800" : "white"
              } tw-rounded-lg tw-shadow-lg tw-overflow-hidden`}
            >
              <div className="tw-overflow-x-auto">
                <table
                  className={`tw-w-full tw-border-collapse ${
                    isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                  }`}
                >
                  <thead
                    className={`tw-bg-${isDarkMode ? "gray-700" : "gray-50"}`}
                  >
                    <tr>
                      <th className="tw-py-4 tw-px-6 tw-text-left tw-font-semibold">
                        Date
                      </th>
                      <th className="tw-py-4 tw-px-6 tw-text-left tw-font-semibold">
                        Amount
                      </th>
                      <th className="tw-py-4 tw-px-6 tw-text-left tw-font-semibold">
                        Status
                      </th>
                      <th className="tw-py-4 tw-px-6 tw-text-left tw-font-semibold">
                        Receipt
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment) => (
                      <tr
                        key={payment.payment_id}
                        className={`tw-border-b ${
                          isDarkMode
                            ? "tw-border-gray-700"
                            : "tw-border-gray-200"
                        } hover:tw-bg-${
                          isDarkMode ? "gray-700" : "gray-50"
                        } tw-transition-colors`}
                      >
                        <td className="tw-py-4 tw-px-6">
                          {format(new Date(payment.created_at), "MMM d, yyyy")}
                        </td>
                        <td className="tw-py-4 tw-px-6 tw-font-medium">
                          {formatCurrency(
                            payment.amount / 100,
                            payment.currency
                          )}
                        </td>
                        <td className="tw-py-4 tw-px-6">
                          <span
                            className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${
                              payment.status.toLowerCase() === "succeeded"
                                ? "tw-bg-green-100 tw-text-green-800"
                                : payment.status.toLowerCase() === "failed"
                                ? "tw-bg-red-100 tw-text-red-800"
                                : "tw-bg-blue-100 tw-text-blue-800"
                            }`}
                          >
                            {payment.status.charAt(0).toUpperCase() +
                              payment.status.slice(1)}
                          </span>
                        </td>
                        <td className="tw-py-4 tw-px-6">
                          {payment.receipt_url && (
                            <div className="tw-flex tw-gap-2">
                              <button
                                onClick={() =>
                                  setSelectedReceipt(payment.receipt_url)
                                }
                                className="tw-flex tw-items-center tw-text-blue-500 hover:tw-text-blue-600 tw-transition-colors"
                              >
                                <Eye className="tw-w-4 tw-h-4 tw-mr-1" />
                                Preview
                              </button>
                              <a
                                href={payment.receipt_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="tw-flex tw-items-center tw-text-blue-500 hover:tw-text-blue-600 tw-transition-colors"
                              >
                                <ExternalLink className="tw-w-4 tw-h-4 tw-mr-1" />
                                Download
                              </a>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination */}
            <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
              <div className="tw-text-sm tw-text-gray-500">
                Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                {Math.min(currentPage * itemsPerPage, totalPayments)} of{" "}
                {totalPayments} payments
              </div>
              <div className="tw-flex tw-gap-2">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`tw-p-2 tw-rounded-lg tw-border ${
                    isDarkMode
                      ? "tw-border-gray-600 tw-text-gray-300 hover:tw-bg-gray-700"
                      : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
                  } ${
                    currentPage === 1
                      ? "tw-opacity-50 tw-cursor-not-allowed"
                      : ""
                  }`}
                >
                  <ChevronLeft className="tw-w-5 tw-h-5" />
                </button>
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter((page) => {
                    const distance = Math.abs(page - currentPage);
                    return (
                      distance === 0 ||
                      distance === 1 ||
                      page === 1 ||
                      page === totalPages
                    );
                  })
                  .map((page, index, array) => (
                    <React.Fragment key={page}>
                      {index > 0 && array[index - 1] !== page - 1 && (
                        <span className="tw-px-2 tw-py-2">...</span>
                      )}
                      <button
                        onClick={() => handlePageChange(page)}
                        className={`tw-px-4 tw-py-2 tw-rounded-lg tw-border ${
                          page === currentPage
                            ? isDarkMode
                              ? "tw-bg-blue-600 tw-text-white tw-border-blue-600"
                              : "tw-bg-blue-500 tw-text-white tw-border-blue-500"
                            : isDarkMode
                            ? "tw-border-gray-600 tw-text-gray-300 hover:tw-bg-gray-700"
                            : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
                        }`}
                      >
                        {page}
                      </button>
                    </React.Fragment>
                  ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`tw-p-2 tw-rounded-lg tw-border ${
                    isDarkMode
                      ? "tw-border-gray-600 tw-text-gray-300 hover:tw-bg-gray-700"
                      : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
                  } ${
                    currentPage === totalPages
                      ? "tw-opacity-50 tw-cursor-not-allowed"
                      : ""
                  }`}
                >
                  <ChevronRight className="tw-w-5 tw-h-5" />
                </button>
              </div>
            </div>
          </>
        )}

        {/* Receipt Preview Modal */}
        {selectedReceipt && (
          <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
            <div className="tw-bg-white tw-rounded-lg tw-shadow-xl tw-w-full tw-max-w-4xl tw-h-[80vh] tw-relative">
              <button
                onClick={() => setSelectedReceipt(null)}
                className="tw-absolute tw-top-4 tw-right-4 tw-text-gray-500 hover:tw-text-gray-700"
              >
                <svg
                  className="tw-w-6 tw-h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <iframe
                src={selectedReceipt}
                className="tw-w-full tw-h-full tw-rounded-lg"
                title="Receipt Preview"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
