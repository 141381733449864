import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckCircle2, XCircle, Loader2 } from "lucide-react";

interface PaymentConfirmationProps {
  isDarkMode: boolean;
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({
  isDarkMode,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<"loading" | "success" | "failed">(
    "loading"
  );

  useEffect(() => {
    const redirectStatus = searchParams.get("redirect_status");

    if (redirectStatus === "succeeded") {
      setStatus("success");
      toast.success("Payment processed successfully!");
    } else {
      setStatus("failed");
      toast.error("Payment processing failed. Please try again.");
    }
  }, [searchParams]);

  return (
    <div className="tw-container tw-mx-auto tw-px-4 tw-py-8">
      <div className="tw-max-w-lg tw-mx-auto">
        <div
          className={`tw-rounded-xl tw-shadow-lg ${
            isDarkMode
              ? "tw-bg-gray-800 tw-text-white"
              : "tw-bg-white tw-text-gray-900"
          } tw-p-8 tw-text-center`}
        >
          {status === "loading" ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
              <Loader2 className="tw-w-16 tw-h-16 tw-text-blue-500 tw-animate-spin" />
              <h2 className="tw-text-xl tw-font-semibold">
                Processing Payment
              </h2>
              <p className="tw-text-gray-500">
                Please wait while we confirm your payment...
              </p>
            </div>
          ) : status === "success" ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
              <CheckCircle2 className="tw-w-16 tw-h-16 tw-text-green-500" />
              <h2 className="tw-text-xl tw-font-semibold">
                Payment Successful!
              </h2>
              <p className="tw-text-gray-500">
                Your rent payment has been processed successfully.
              </p>
              <button
                onClick={() => navigate("/dashboard")}
                className={`tw-mt-6 tw-px-6 tw-py-2 tw-rounded-lg tw-font-medium
                  ${
                    isDarkMode
                      ? "tw-bg-green-600 tw-text-white hover:tw-bg-green-700"
                      : "tw-bg-green-500 tw-text-white hover:tw-bg-green-600"
                  }`}
              >
                Return to Dashboard
              </button>
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-4">
              <XCircle className="tw-w-16 tw-h-16 tw-text-red-500" />
              <h2 className="tw-text-xl tw-font-semibold">Payment Failed</h2>
              <p className="tw-text-gray-500">
                We couldn't process your payment. Please try again or contact
                support if the issue persists.
              </p>
              <div className="tw-flex tw-gap-4 tw-mt-6">
                <button
                  onClick={() => window.history.back()}
                  className={`tw-px-6 tw-py-2 tw-rounded-lg tw-font-medium
                    ${
                      isDarkMode
                        ? "tw-bg-red-600 tw-text-white hover:tw-bg-red-700"
                        : "tw-bg-red-500 tw-text-white hover:tw-bg-red-600"
                    }`}
                >
                  Try Again
                </button>
                <button
                  onClick={() => navigate("/dashboard")}
                  className={`tw-px-6 tw-py-2 tw-rounded-lg tw-font-medium tw-border
                    ${
                      isDarkMode
                        ? "tw-border-gray-600 tw-text-gray-300 hover:tw-bg-gray-700"
                        : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
                    }`}
                >
                  Return to Dashboard
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
