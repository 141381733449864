import http from "../general/httpService";
import config from "../../constants/config.json";
import {
  PaymentSchedule,
  PaymentIntent,
  PaymentHistoryRecord,
  PaymentDashboardData,
  PaymentStatus,
  StripeSetupIntent,
  TenantPaymentDetailsResponse,
  TenantPaymentListResponse,
} from "./types";

// Get current payment schedule
export async function getCurrentSchedule(
  rentalContractId: string
): Promise<PaymentSchedule> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/current_schedule/${rentalContractId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

// Get next payment date
export async function getNextPaymentDate(
  rentalContractId: string
): Promise<PaymentDashboardData> {
  const tenantId = localStorage.getItem(config.tenant_id);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_payment/get_next_payment_date/${rentalContractId}/${tenantId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

// Create Stripe Setup Intent
export async function createSetupIntent(
  rentalContractId: string
): Promise<StripeSetupIntent> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/setup_intent/${rentalContractId}`;
  const { data: response } = await http.post(fullApiEndpoint);
  return response;
}

// Create Payment Intent for rent payment
export async function createPaymentIntent(
  rentalContractId: string,
  data: {
    amount: number;
    currency?: string;
    rental_fee_pack_id: string;
  }
): Promise<PaymentIntent> {
  const tenantId = localStorage.getItem(config.tenant_id);
  if (!tenantId) {
    throw new Error("Tenant ID not found");
  }

  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/create_payment_intent`;
  const requestData = {
    ...data,
    tenant_id: tenantId,
    rental_contract_id: rentalContractId,
    currency: data.currency || "USD",
  };
  const { data: response } = await http.post(fullApiEndpoint, requestData);
  return response;
}

// Confirm Payment Intent
export async function confirmPaymentIntent(
  paymentIntentId: string
): Promise<PaymentStatus> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/confirm_payment/${paymentIntentId}`;
  const { data: response } = await http.post(fullApiEndpoint);
  return response;
}

// Setup payment schedule
export async function setupPaymentSchedule(data: {
  rental_contract_id: string;
  is_automatic: boolean;
  payment_day: number;
}): Promise<PaymentSchedule> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/setup_payment_schedule`;
  const { data: response } = await http.post(fullApiEndpoint, data);
  return response;
}

// Process rent payment
export async function processRentPayment(
  rentalContractId: string
): Promise<PaymentStatus> {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_payment/process_rent_payment/${rentalContractId}`;
  const { data: response } = await http.post(fullApiEndpoint);
  return response;
}

// Get payment history
export async function getPaymentHistoryRecord(
  rentalContractId: string,
  filters?: {
    startDate?: string;
    endDate?: string;
    status?: string;
    type?: "manual" | "automatic";
  }
): Promise<PaymentHistoryRecord[]> {
  const queryParams = new URLSearchParams();
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });
  }

  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_payment/payment_history/${rentalContractId}?${queryParams.toString()}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

// Update payment schedule
export async function updatePaymentSchedule(
  scheduleId: string,
  data: {
    is_automatic: boolean;
    payment_day: number;
  }
): Promise<PaymentSchedule> {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_payment/update_payment_schedule/${scheduleId}`;
  const { data: response } = await http.put(fullApiEndpoint, data);
  return response;
}

// Cancel auto payment
export async function cancelAutoPayment(scheduleId: string): Promise<void> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/cancel_auto_payment/${scheduleId}`;
  const { data: response } = await http.post(fullApiEndpoint);
  return response;
}

// Get tenant payment details
export async function getTenantPaymentDetails(
  paymentId: string
): Promise<TenantPaymentDetailsResponse> {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_payment/payment_details/${paymentId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

// Get tenant payments with pagination
export async function getTenantPayments(
  rentalContractId: string,
  page: number = 1,
  limit: number = 10
): Promise<TenantPaymentListResponse> {
  const tenantId = localStorage.getItem(config.tenant_id);
  if (!tenantId) {
    throw new Error("Tenant ID not found");
  }

  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_payment/tenant_payments/${rentalContractId}/${tenantId}/${page}/${limit}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}
