import * as React from "react";

const defaultTenantProfile = {
  tenant_id: "",
  first_name: "",
  last_name: "",
  email_address: "",
  stripe_customer_id: "",
  phone_number: "",
  campaign_id: "",
  profile_image_url: "",
  pre_screening_detail: {
    duration_of_current_residence: "",
    total_monthly_income: "",
    sources_of_income: [
      {
        job_category_id: "",
        job_category: "",
        created_at: new Date(),
        updated_at: new Date(),
      },
    ],
    number_of_occupants: "",
    reason_for_leaving: "",
    desired_move_in_date: new Date(),
    does_any_one_smoke: false,
    has_pets: false,
    describe_pets: "",
  },
};

type TenantProfileContextType = {
  tenantProfile: typeof defaultTenantProfile;
  setTenantProfile: (profile: typeof defaultTenantProfile) => void;
};

const TenantProfileContext = React.createContext<TenantProfileContextType>({
  tenantProfile: defaultTenantProfile,
  setTenantProfile: () => {},
});

export const useTenantProfileContext = () => {
  const context = React.useContext(TenantProfileContext);
  if (!context) {
    throw new Error(
      "useTenantProfileContext must be used within a TenantProfileProvider"
    );
  }
  return context;
};

export const TenantProfileProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tenantProfile, setTenantProfile] =
    React.useState(defaultTenantProfile);

  const value = React.useMemo(
    () => ({
      tenantProfile,
      setTenantProfile,
    }),
    [tenantProfile]
  );

  return (
    <TenantProfileContext.Provider value={value}>
      {children}
    </TenantProfileContext.Provider>
  );
};
