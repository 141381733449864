import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Alert, Spinner } from "react-bootstrap";
import { formatCurrency } from "../../../../utils/currencyUtils";
import { PaymentDashboardData } from "../../../../services/payment/types";
import { getNextPaymentDate } from "../../../../services/payment/tenantPaymentService";
import { CalendarIcon, CreditCard, Clock, AlertCircle } from "lucide-react";
import PageTitleBox from "components/reusable/title/pageTitleBox";
import { usePageTitle } from "../../../../utils/hooks/usePageTitle";

interface PaymentDashboardProps {
  isDarkMode: boolean;
  rentalContractId?: string;
}
//BUG: didnt automatically charge the tenant when it was to be charged
export const PaymentDashboard: React.FC<PaymentDashboardProps> = ({
  isDarkMode,
  rentalContractId: propRentalContractId,
}) => {
  usePageTitle({
    title: "Payment Dashboard",
  });
  const navigate = useNavigate();
  const { rentalContractId: paramRentalContractId } = useParams<{
    rentalContractId: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentDashboardData | null>(
    null
  );

  // Use prop value if available, otherwise use URL parameter
  const rentalContractId = propRentalContractId || paramRentalContractId;

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      if (!rentalContractId) {
        setError("No rental contract specified");
        return;
      }

      try {
        setLoading(true);
        const data = await getNextPaymentDate(rentalContractId);
        setPaymentInfo(data);
      } catch (err) {
        setError("Failed to load payment information. Please try again later.");
        console.error("Error fetching payment info:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentInfo();
  }, [rentalContractId]);

  const handlePayNow = () => {
    if (rentalContractId) {
      navigate(`/paymentManagement/process/${rentalContractId}`);
    }
  };

  const handleSetupSchedule = () => {
    if (rentalContractId) {
      navigate(`/paymentManagement/schedule/${rentalContractId}`);
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            Loading payment information...
          </span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!paymentInfo) return null;

  const nextPaymentDate = new Date(paymentInfo.next_payment_date);
  const today = new Date();
  const daysUntilPayment = Math.ceil(
    (nextPaymentDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );

  const getPaymentStatus = () => {
    if (daysUntilPayment < 0)
      return { status: "overdue", color: "danger", icon: AlertCircle };
    if (daysUntilPayment <= 3)
      return { status: "due", color: "warning", icon: Clock };
    return { status: "upcoming", color: "success", icon: CalendarIcon };
  };

  const paymentStatus = getPaymentStatus();

  return (
    <>
      <div>
        <PageTitleBox
          pageTitle="Payment Dashboard"
          previousPageTitle="Home"
          previousPageLink="/"
          isDarkMode={isDarkMode}
        />
        {/* Payment Status Card */}
        <Card
          bg={isDarkMode ? "dark" : "light"}
          text={isDarkMode ? "white" : "dark"}
          className="mb-4"
        >
          <Card.Header>
            <Card.Title className="d-flex justify-content-between align-items-center">
              <span>Payment Status</span>
              {paymentInfo.is_automatic && (
                <span className="badge bg-success">Auto-Pay Enabled</span>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <p className="text-muted small mb-1">Next Payment Due</p>
                <div className="d-flex align-items-center">
                  <paymentStatus.icon
                    className={`text-${paymentStatus.color} me-2`}
                  />
                  <p className="h4 mb-0">
                    {nextPaymentDate.toLocaleDateString()}
                    <span className={`ms-2 small text-${paymentStatus.color}`}>
                      ({Math.abs(daysUntilPayment)} days{" "}
                      {daysUntilPayment < 0 ? "overdue" : "until due"})
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Quick Actions */}
            <div className="d-flex gap-3">
              <Button
                variant={
                  paymentStatus.status === "overdue" ? "danger" : "primary"
                }
                className="flex-grow-1"
                onClick={handlePayNow}
              >
                Pay Now
              </Button>
              <Button
                variant="outline-primary"
                className="flex-grow-1"
                onClick={handleSetupSchedule}
              >
                {paymentInfo.is_automatic
                  ? "Update Schedule"
                  : "Setup Auto-Pay"}
              </Button>
            </div>
          </Card.Body>
        </Card>

        {/* Payment Schedule Widget */}
        <Card
          bg={isDarkMode ? "dark" : "light"}
          text={isDarkMode ? "white" : "dark"}
        >
          <Card.Header>
            <Card.Title>Payment Schedule</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              {paymentInfo.is_automatic ? (
                <CreditCard className="text-success me-3" size={24} />
              ) : (
                <Clock className="text-warning me-3" size={24} />
              )}
              <div>
                <h5 className="mb-1">
                  {paymentInfo.is_automatic
                    ? "Automatic Payment"
                    : "Manual Payment"}
                </h5>
                <p className="text-muted mb-0">
                  Scheduled for day {paymentInfo.payment_day} of each month
                </p>
              </div>
            </div>

            {paymentInfo.is_automatic && (
              <Alert variant="info" className="mb-0">
                Your payment will be automatically processed on day{" "}
                {paymentInfo.payment_day} of each month. You can update your
                payment schedule or disable auto-pay at any time.
              </Alert>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default PaymentDashboard;
