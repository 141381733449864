import http from "../general/httpService";
import config from "../../constants/config.json";
import { devError } from "utils/logger";

export interface CreatePaymentIntentRequest {
  amount: number;
  currency: string;
  rental_contract_id: string;
  rental_fee_pack_id: string;
  tenant_id: string;
}

export interface PaymentIntentSuccessResponse {
  error: false;
  client_secret: string;
  amount: number;
  currency: string;
  payment_intent_id: string;
  platform_fee_amount: number;
}

export interface PaymentIntentErrorResponse {
  error: true;
  status_code: number;
  message: string;
  requires_action:
    | "wait_for_landlord"
    | "wait_for_verification"
    | "try_again"
    | "authenticate"
    | "update_card"
    | "contact_support";
}

export type PaymentIntentResponse =
  | PaymentIntentSuccessResponse
  | PaymentIntentErrorResponse;

export interface PaymentConfirmationResponse {
  success: boolean;
  payment_id: string;
  status: string;
  amount: number;
  currency: string;
}

export class PaymentError extends Error {
  public status_code: number;
  public requires_action: string;

  constructor(message: string, status_code: number, requires_action: string) {
    super(message);
    this.name = "PaymentError";
    this.status_code = status_code;
    this.requires_action = requires_action;
  }
}

/**
 * Create a payment intent for rental fees
 */
export async function createPaymentIntent(
  data: CreatePaymentIntentRequest
): Promise<PaymentIntentSuccessResponse> {
  try {
    const fullApiEndpoint = `${config.apiEndpoint}/tenant_payment/create_payment_intent`;

    const requestData = {
      ...data,
      amount: data.amount,
    };

    const { data: response } = await http.post<PaymentIntentResponse>(
      fullApiEndpoint,
      requestData
    );

    // Check if the response indicates an error
    if ("error" in response && response.error === true) {
      const errorResponse = response as PaymentIntentErrorResponse;
      throw new PaymentError(
        errorResponse.message,
        errorResponse.status_code,
        errorResponse.requires_action
      );
    }

    // If no error, it must be a success response
    const successResponse = response as PaymentIntentSuccessResponse;
    if (!successResponse.client_secret) {
      throw new Error("No client secret returned from the server");
    }

    return successResponse;
  } catch (error: any) {
    devError("Error creating payment intent:", error);

    // If it's already a PaymentError, rethrow it
    if (error instanceof PaymentError) {
      throw error;
    }

    // Handle other types of errors
    if (error.response?.data?.detail) {
      throw new PaymentError(
        error.response.data.detail,
        error.response.status || 500,
        "try_again"
      );
    }

    throw new PaymentError(
      "Failed to create payment intent. Please try again.",
      500,
      "try_again"
    );
  }
}

/**
 * Confirm a payment using the payment intent ID
 */
export async function confirmPayment(
  paymentIntentId: string
): Promise<PaymentConfirmationResponse> {
  try {
    const fullApiEndpoint = `${config.apiEndpoint}/tenant_payment/confirm_payment/${paymentIntentId}`;

    const { data: response } = await http.post(fullApiEndpoint);

    if (!response.status) {
      throw new Error("Invalid response from payment confirmation");
    }

    return response;
  } catch (error: any) {
    devError("Error confirming payment:", error);
    if (error.response?.data?.detail) {
      throw new PaymentError(
        error.response.data.detail,
        error.response.status || 500,
        "try_again"
      );
    }
    throw new PaymentError(
      "Failed to confirm payment. Please try again.",
      500,
      "try_again"
    );
  }
}
