// Environment Variables
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Payment Status
export const PAYMENT_STATUS = {
  SUCCEEDED: "succeeded",
  FAILED: "failed",
  PENDING: "pending",
  PROCESSING: "processing",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
  REQUIRES_CONFIRMATION: "requires_confirmation",
};

// Payment Types
export const PAYMENT_TYPES = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
};

// Payment Method Types
export const PAYMENT_METHOD_TYPES = {
  CARD: "card",
  BANK_ACCOUNT: "bank_account",
};
