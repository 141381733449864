const LoaderView = ({ isDarkMode = false }) => {
  return (
    <div id="preloader">
      <div id="status">
        <div
          className={`spinner-border text-primary avatar-sm ${
            isDarkMode ? "text-white dark:text-white" : "text-primary"
          }`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoaderView;
