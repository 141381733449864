import React, { useEffect, useState } from "react";
import { useParams, Outlet } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { getRentalContractById } from "../../../services/rental/contract/rentalContractService";

interface PaymentManagementProps {
  isDarkMode: boolean;
}

export const PaymentManagement: React.FC<PaymentManagementProps> = ({
  isDarkMode,
}) => {
  const { rentalContractId } = useParams<{ rentalContractId: string }>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const validateContract = async () => {
      if (!rentalContractId) {
        setError("No rental contract specified");
        return;
      }

      try {
        const contract = await getRentalContractById(rentalContractId);
        if (!contract) {
          setError("Invalid rental contract");
          return;
        }
      } catch (err) {
        setError("Failed to load rental contract information");
        console.error("Error validating rental contract:", err);
      }
    };

    validateContract();
  }, [rentalContractId]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="tw-w-full">
      <Outlet context={{ isDarkMode }} />
    </div>
  );
};
