import http from "../../general/httpService";
import config from "../../../constants/config.json";
import {
  PaymentReceiptFilter,
  PaymentReceiptModel,
} from "./model/paymentReceiptModel";

export async function getPaymentReceipt(
  receiptId: string
): Promise<PaymentReceiptModel> {
  const fullApiEndpoint = config.apiEndpoint + `/payment_receipt/${receiptId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

export async function getPaymentReceiptsByRentalContract(): Promise<
  PaymentReceiptModel[]
> {
  const tenantId = localStorage.getItem(config.tenant_id);
  const rentalContractId = localStorage.getItem(config.rentalContractId);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/payment_receipt/tenant/${tenantId}/rental_contract/${rentalContractId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}

///search for payment receipts
export async function searchPaymentReceipts(
  searchParams: PaymentReceiptFilter
): Promise<PaymentReceiptModel[]> {
  const fullApiEndpoint = config.apiEndpoint + `/payment_receipt/search`;
  const { data: response } = await http.get(fullApiEndpoint, {
    params: searchParams,
  });
  return response;
}
