import http from "../../general/httpService";
import config from "../../../constants/config.json";
import jwtDecode from "jwt-decode";
import { firebase_auth } from "../../../firebase";
import CryptoJS from "crypto-js";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
//declaring the constants
const tokenKey = config.access_token;

const email = config.email;
const userKey = config.tenant_id;

//TODO: When the tenant fills out the application, it will create a version, the tenant can copy a previous version to pre-fill parts of a new application and they can view the previous versions sent to various landlords

//update_tenant_profile
export async function updateTenantProfileMainAppli(tenantProfileData) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenantProfileManagement/api/update_tenant_profile_main_appli`;
  const response = await http.put(fullApiEndpoint, tenantProfileData);

  return response;
}

export function generateKey(tenantProfile) {
  const emailAddressWithoutSpecialCharacters =
    tenantProfile.email_address.replace(/[^a-zA-Z0-9]/g, "");
  return `${
    tenantProfile.tenant_id
  }_${emailAddressWithoutSpecialCharacters}_${tenantProfile.first_name.toLowerCase()}`;
}

export function decryptData(encryptedData, key) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

///api/get_all_application_snapshots_by_tenant/:tenantId
export async function getAllApplicationSnapshotsByTenant(tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenantApplicManagement/api/get_all_application_snapshots_by_tenant/${tenantId}`;
  const response = await http.get(fullApiEndpoint);
  return response;
}

//tenant_rental_application/get_tenant_rental_application_by_campaign_id
export async function getTenantRentalApplicationByCampaignId(campaignId) {
  const tenant_id = localStorage.getItem(config.tenant_id);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_tenant_rental_application_by_campaign_id/${campaignId}/${tenant_id}`;
  const response = await http.get(fullApiEndpoint);
  return response;
}

//tenant_rental_application/update_tenant_rental_application
export async function updateTenantRentalApplication(tenantRentalApplication) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/update_tenant_rental_application`;
  const response = await http.put(fullApiEndpoint, tenantRentalApplication);
  return response;
}

//tenant_rental_application/get_property_unit_by_property_unit_id
export async function getPropertyUnitByPropertyUnitId(propertyUnitId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_property_unit_by_property_unit_id/${propertyUnitId}`;
  const response = await http.get(fullApiEndpoint);
  return response;
}

//tenant_rental_application/delete_attachment/{application_id}/{unique_file_name}
export async function deleteAttachment(applicationId, uniqueFileName) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/delete_attachment/${applicationId}/${uniqueFileName}`;
  const { data: response } = await http.delete(fullApiEndpoint);
  return response;
}
