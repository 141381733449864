import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getRentalContractById } from "services/rental/contract/rentalContractService";
import { getRentalFeePackById } from "services/rental/fees/rentalFeePackService";
import {
  createPaymentIntent,
  confirmPayment,
} from "services/payment/stripePaymentService";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "../../StripePaymentForm";
import { formatCurrency } from "utils/currencyUtils";
import { toast } from "react-toastify";
import { getCurrencyById } from "services/systemDefined/currency/currencyService";
import { devError, devLog } from "utils/logger";
import { fetchUnitWithImages } from "services/property/advertisement/propertyAdvertisement";
import PageTitleBox from "components/reusable/title/pageTitleBox";
const defaultHouseImage = "/images/buildings/house.png";

// Load Stripe with appearance options
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  devError(
    "Missing Stripe publishable key. Please check your environment variables."
  );
}

//may need to add this to an interface file
interface Signature {
  signature_id: string;
  rental_contract_id: string;
  signature_url: string; // aws s3 url
  signature_base64?: string; // base64 string (not for storage)
  signed_at: Date;
  signed_by: string;
  is_signed: boolean;
  email_address: string;
}

interface LandlordPropertyManagerSignature extends Signature {
  user_id: string;
  user_type: string;
  landlord_id: string;
}

interface TenantSignature extends Signature {
  user_type: string;
  tenant_id: string;
}

interface RentalContract {
  rental_contract_id: string;
  tenant_id: string;
  property_unit_id: string;
  start_date: string;
  end_date: string;
  monthly_rent: number;
  security_deposit: number;
  contract_template_id: string;
  contract_detail: string;
  portfolio_ownership_id: string;
  property_name: string;
  property_unit_name: string;
  portfolio_name: string;
  owner_manager_user_id: string;
  owner_manager_name: string;
  owner_manager_email: string;
  end_of_lease_action: string;
  is_active: boolean;
  is_deleted: boolean;
  rental_fee_pack_template_id: string;
  rental_fee_pack_id: string;
  screening_pack_id: string;
  campaign_id: string;
  landlord_property_manager_signatures: LandlordPropertyManagerSignature[];
  tenant_signatures: TenantSignature[];
}

interface UserDefinedCharge {
  charge_name: string;
  charge_amount: number;
}

interface RentalFeePack {
  rental_fee_pack_template_id: string;
  fee_pack_name: string;
  fee_pack_description: string;
  require_security_deposit: boolean;
  require_last_month_rent: boolean;
  require_pet_deposit: boolean;
  require_pet_rent: boolean;
  collect_prorated_rent: boolean;
  automatically_increase_rent: boolean;
  rent_increase_percentage: number;
  rent_increase_period: string;
  rent_paid_through_gurenter: boolean;
  require_guarantor: boolean;
  bank_account_id: string;
  bank_id: string;
  bank_name: string;
  bank_account_name: string;
  bank_account_number: string;
  bank_routing_number: string;
  is_bank_verified: boolean;
  created_at: Date;
  created_by: string;
  user_id: string;
  user_defined_charges: UserDefinedCharge[];
  currency_id: string;
  currency_name: string;
}

// Add new interfaces for fee breakdown
interface Fee {
  name: string;
  amount: number;
}

interface FeeBreakdownCardProps {
  unitImage?: string;
  unitName: string;
  propertyAddress: string;
  fees: Fee[];
  currency?: string;
}

interface Currency {
  currency_id: string;
  currency_code: string;
  currency_country: string;
  currency_name: string;
  currency_symbol: string;
}

//Property Unit Image
interface PropertyUnitImage {
  base64_data: string;
  file_name: string;
  unique_file_name: string;
  file_size: number;
  file_size_formatted: string;
  file_type: string;
  upload_time_stamp: Date;
  url: string;
}

interface PropertyUnit {
  property_unit_id: string;
  property_id: string;
  unit_name: string;
  number_of_bedrooms: number;
  number_of_bathrooms: number;
  square_footage: number;
  market_rent_amount: number;
  is_rented: boolean;
  property_unit_image_urls: PropertyUnitImage[];
}

const StartOfLeaseFeesPage = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const { rentalContractId } = useParams();
  const navigate = useNavigate();
  const [rentalContract, setRentalContract] = useState<RentalContract | null>(
    null
  );
  const [rentalFeePack, setRentalFeePack] = useState<RentalFeePack | null>(
    null
  );
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currencyCode, setCurrencyCode] = useState<string | undefined>("USD");
  const [propertyUnit, setPropertyUnit] = useState<PropertyUnit | null>(null);
  const calculateFees = (): Fee[] => {
    if (!rentalContract || !rentalFeePack) return [];

    const fees: Fee[] = [];

    // Add security deposit if required
    if (rentalFeePack.require_security_deposit) {
      fees.push({
        name: "Security Deposit",
        amount: rentalContract.security_deposit,
      });
    }

    // Add first month's rent
    fees.push({
      name: "First Month's Rent",
      amount: rentalContract.monthly_rent,
    });

    // Add last month's rent if required
    if (rentalFeePack.require_last_month_rent) {
      fees.push({
        name: "Last Month's Rent",
        amount: rentalContract.monthly_rent,
      });
    }

    // Add user defined charges
    rentalFeePack.user_defined_charges.forEach((charge) => {
      fees.push({
        name: charge.charge_name,
        amount: charge.charge_amount,
      });
    });

    return fees;
  };

  const calculateTotalAmount = (fees: Fee[]): number => {
    return fees.reduce((sum, fee) => sum + fee.amount, 0);
  };

  const fetchCurrency = async (currencyId: string): Promise<Currency> => {
    const currency = await getCurrencyById(currencyId);
    devLog("Currency fetched:", currency);
    if (!currency) {
      devError("Currency not found");
    }

    return currency;
  };

  const fetchPropertyUnit = async (propertyUnitId: string) => {
    const propertyUnit = await fetchUnitWithImages(propertyUnitId);
    devLog("Property unit fetched:", propertyUnit);
    if (!propertyUnit) {
      devError("Property unit not found");
    } else if (propertyUnit) {
      setPropertyUnit(propertyUnit);
    }
  };

  const initializePayment = async () => {
    if (!rentalContract || !rentalFeePack) {
      console.log("Missing contract or fee pack data:", {
        rentalContract,
        rentalFeePack,
      });
      return;
    }

    //currency_id is the id of the currency in the rentalFeePack
    const currencyId = rentalFeePack.currency_id;
    //get the currency_code from the currency id
    const currency = await fetchCurrency(currencyId);
    const currencyCode = currency.currency_code;
    setCurrencyCode(currencyCode);
    const fees = calculateFees();
    const totalAmount = calculateTotalAmount(fees);
    console.log("Calculated fees:", fees);
    console.log("Total amount:", totalAmount);

    if (totalAmount <= 0) {
      console.error("Total amount must be greater than 0");
      setError("Invalid payment amount");
      return;
    }

    try {
      setIsLoading(true);
      setError(null); // Clear any previous errors

      const paymentData = {
        tenant_id: rentalContract.tenant_id,
        amount: totalAmount,
        currency: currencyCode, //Need to set this to the currency of the rental contract
        rental_contract_id: rentalContract.rental_contract_id,
        rental_fee_pack_id: rentalFeePack.rental_fee_pack_template_id,
      };

      console.log("Creating payment intent with data:", paymentData);

      const response = await createPaymentIntent(paymentData);
      console.log("Payment intent created successfully:", response);

      if (!response.client_secret) {
        throw new Error("Missing client secret in response");
      }

      setClientSecret(response.client_secret);
    } catch (err: any) {
      console.error("Payment initialization error:", err);

      // Handle FastAPI error format
      const errorMessage =
        err.response?.data?.detail ||
        err.message ||
        "Failed to initialize payment";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = async (paymentIntentId: string) => {
    try {
      const confirmationResult = await confirmPayment(paymentIntentId);

      if (confirmationResult.success) {
        toast.success("Payment successful!");
        navigate(`/dashboard`);
        // TODO: Redirect to success page or dashboard
        //TODO: need a way to give the landlord a task to verify the payment (TODO list so they can verify the payment which will update the tenant application etc)
        //TODO: next update the landlord that the payment was successful and auto do the confirmation
        //of payments, also initiate so the money goes to the landlord account thats linked
        //TODO: need to set up the fall back for places where where is no stripe (manualy verify by landlord)

        //TODO: generate a rental reciept for the security deposit, first month rent and the other fees, viewable in the tenant portal and the landlord portal
        //Once confirmed by the system or landlord the property is no longer available and all the other tenants are notified that the property is now rented
        //the chosen tenant is notified that the property is rented and the tenant can now move in
        //The tenant can now see their payment history and submit tickets etc.
      } else {
        throw new Error(`Payment failed: ${confirmationResult.status}`);
      }
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.detail ||
        err.message ||
        "Payment confirmation failed";
      toast.error(errorMessage);
      setError(errorMessage);
    }
  };

  const handlePaymentError = (errorMessage: string) => {
    console.error("Payment error:", errorMessage);
    toast.error(errorMessage);
    setError(errorMessage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        console.log("Fetching rental contract data...");
        const contractData = await getRentalContractById(rentalContractId);
        console.log("Contract data received:", contractData);
        setRentalContract(contractData);

        if (contractData.rental_fee_pack_id) {
          console.log("Fetching fee pack data...");
          const feePackData = await getRentalFeePackById(
            contractData.rental_fee_pack_id
          );
          console.log("Fee pack data received:", feePackData);
          setRentalFeePack(feePackData);
        }

        if (contractData.property_unit_id) {
          devLog("Fetching property unit data...");
          await fetchPropertyUnit(contractData.property_unit_id);
        }
      } catch (err) {
        console.error("Data fetching error:", err);
        setError("Failed to load rental information");
      } finally {
        setIsLoading(false);
      }
    };

    if (rentalContractId) {
      fetchData();
    }
  }, [rentalContractId]);

  useEffect(() => {
    if (rentalContract && rentalFeePack && !clientSecret) {
      console.log("Initializing payment with contract and fee pack data");
      initializePayment();
    }
  }, [rentalContract, rentalFeePack]);

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen">
        <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen">
        <div className="tw-text-red-500">{error}</div>
      </div>
    );
  }

  const fees = calculateFees();
  const totalAmount = calculateTotalAmount(fees);

  return (
    <div className="page-content">
      <PageTitleBox
        pageTitle="Start of Lease Fees"
        previousPageTitle="Rental Contract"
        previousPageLink={`/portal/rental/contract/${rentalContractId}`}
        isDarkMode={isDarkMode}
      />
      <main className={`tw-min-h-screen `}>
        <div className="tw-max-w-7xl tw-mx-auto tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8 tw-px-4 tw-py-8">
          {/* Left side - Payment Form */}
          <div
            className={`${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-rounded-lg tw-shadow-lg tw-p-8`}
          >
            <div className="tw-max-w-lg tw-mx-auto">
              <h2
                className={`tw-text-2xl tw-font-semibold tw-mb-6 ${
                  isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                }`}
              >
                Payment Details
              </h2>
              {clientSecret ? (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    appearance: {
                      theme: isDarkMode ? "night" : "stripe",
                      variables: {
                        colorPrimary: "#4F46E5",
                        colorBackground: isDarkMode ? "#1F2937" : "#ffffff",
                        colorText: isDarkMode ? "#F3F4F6" : "#1F2937",
                        borderRadius: "8px",
                      },
                      rules: {
                        ".Input": {
                          border: isDarkMode
                            ? "1px solid #374151"
                            : "1px solid #E5E7EB",
                          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                          padding: "10px 12px",
                          backgroundColor: isDarkMode ? "#374151" : "#ffffff",
                        },
                        ".Input:focus": {
                          border: "2px solid #4F46E5",
                          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                        },
                        ".Label": {
                          color: isDarkMode ? "#F3F4F6" : "#1F2937",
                        },
                      },
                    },
                  }}
                >
                  <StripePaymentForm
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentError={handlePaymentError}
                    isDarkMode={isDarkMode}
                  />
                </Elements>
              ) : (
                <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
                  <div
                    className={`tw-flex tw-items-center ${
                      isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                    }`}
                  >
                    <svg
                      className="tw-animate-spin tw-h-5 tw-w-5 tw-mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="tw-opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="tw-opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Initializing payment form...
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Right side - Fee Breakdown */}
          <div
            className={`${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-rounded-lg tw-shadow-lg tw-p-8`}
          >
            <div className="tw-max-w-lg">
              <div className="tw-mb-8">
                <div className="tw-flex tw-items-start tw-gap-6">
                  {/* Unit Image */}
                  <div className="tw-flex-shrink-0">
                    <img
                      src={
                        propertyUnit?.property_unit_image_urls?.[0]?.url ||
                        defaultHouseImage
                      }
                      alt={rentalContract?.property_unit_name || "Unit"}
                      className="tw-w-24 tw-h-24 tw-object-cover tw-rounded-lg tw-shadow-md"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = defaultHouseImage;
                      }}
                    />
                  </div>

                  {/* Unit Details */}
                  <div className="tw-flex-grow">
                    <h2
                      className={`tw-text-3xl tw-font-bold ${
                        isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                      }`}
                    >
                      {rentalContract?.property_unit_name || "Unit Name"}
                    </h2>
                    <p
                      className={`tw-text-lg ${
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                      }`}
                    >
                      {rentalContract?.property_name || "Property Address"}
                    </p>

                    {/* Optional Unit Details */}
                    {propertyUnit && (
                      <div className="tw-flex tw-gap-4 tw-mt-2">
                        {propertyUnit.number_of_bedrooms && (
                          <span
                            className={`tw-text-sm ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            }`}
                          >
                            {propertyUnit.number_of_bedrooms} Beds
                          </span>
                        )}
                        {propertyUnit.number_of_bathrooms && (
                          <span
                            className={`tw-text-sm ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            }`}
                          >
                            {propertyUnit.number_of_bathrooms} Baths
                          </span>
                        )}
                        {propertyUnit.square_footage && (
                          <span
                            className={`tw-text-sm ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            }`}
                          >
                            {propertyUnit.square_footage} sq ft
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="tw-space-y-6">
                {fees.map((fee, index) => (
                  <div
                    key={index}
                    className={`tw-flex tw-justify-between tw-items-center tw-py-4 ${
                      index !== fees.length - 1
                        ? `tw-border-b ${
                            isDarkMode
                              ? "tw-border-gray-700"
                              : "tw-border-gray-200"
                          }`
                        : ""
                    }`}
                  >
                    <div>
                      <h3
                        className={`tw-text-base tw-font-medium ${
                          isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                        } tw-font-inter`}
                      >
                        {fee.name}
                      </h3>
                    </div>
                    <p
                      className={`tw-text-lg ${
                        isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                      } tw-font-inter`}
                    >
                      {formatCurrency(fee.amount)}
                    </p>
                  </div>
                ))}

                {/* Total Amount Section - No border above */}
                <div className="tw-pt-6 tw-mt-6">
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <dt
                      className={`tw-text-xl tw-font-bold ${
                        isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                      } tw-font-inter`}
                    >
                      Total Amount Due
                    </dt>
                    <dd
                      className={`tw-text-xl tw-font-bold ${
                        isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                      } tw-font-inter`}
                    >
                      {currencyCode ? (
                        formatCurrency(totalAmount, currencyCode)
                      ) : (
                        <span className="tw-text-gray-500">
                          Loading currency...
                        </span>
                      )}
                    </dd>
                  </div>
                </div>

                <div className="tw-mt-8">
                  <h3
                    className={`tw-text-base tw-font-medium tw-mb-2 ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    Payment Information
                  </h3>
                  <p
                    className={`tw-text-sm ${
                      isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                    }`}
                  >
                    Please complete the payment form to process your start of
                    lease fees. All transactions are secure and encrypted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StartOfLeaseFeesPage;
