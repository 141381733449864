import { useEffect } from "react";

interface PageTitleConfig {
  title: string;
  prefix?: string;
  suffix?: string;
}

/**
 * Custom hook to manage page title
 * @param config - PageTitleConfig object containing title, optional prefix and suffix
 * @example
 * // Basic usage
 * usePageTitle({ title: 'Dashboard' }); // Sets title to "Dashboard"
 *
 * // With prefix
 * usePageTitle({ title: 'Dashboard', prefix: 'Gurenter' }); // Sets title to "Gurenter | Dashboard"
 *
 * // With suffix
 * usePageTitle({ title: 'Dashboard', suffix: 'Tenant Portal' }); // Sets title to "Dashboard | Tenant Portal"
 *
 * // With both prefix and suffix
 * usePageTitle({
 *   title: 'Dashboard',
 *   prefix: 'Gurenter',
 *   suffix: 'Tenant Portal'
 * }); // Sets title to "Gurenter | Dashboard | Tenant Portal"
 */
export const usePageTitle = (config: PageTitleConfig): void => {
  useEffect(() => {
    const { title, prefix, suffix } = config;
    const parts = [prefix, title, suffix].filter(Boolean);
    document.title = parts.join(" | ");

    //default prefix if not provided prefix is Gurenter Tenant include the provided title
    if (!prefix) {
      document.title = `Gurenter Tenant | ${title}`;
      //append suffix if provided
      if (suffix) {
        document.title = `${document.title} | ${suffix}`;
      }
    }

    // Cleanup function to reset title when component unmounts
    return () => {
      document.title = prefix || "Gurenter Tenant";
    };
  }, [config]);
};
