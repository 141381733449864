import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/styletheme/css/bootstrap.min.css";
import "../src/styletheme/css/icons.min.css";
import "../src/styletheme/css/app.css";
import "../src/styletheme/css/custom.min.css";
import { TenantProfileProvider } from "./services/authentication/contexts/tenantProfile/currentTenantProfileContext";
import { SettingsProvider } from "./services/authentication/contexts/settingsContext";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from "./constants/constants";

const root = ReactDOM.createRoot(document.getElementById("root"));
const environment =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || "development";

const getSentryConfig = (env) => {
  switch (env) {
    case "production":
      return {
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      };
    case "staging":
      return {
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.5,
        replaysOnErrorSampleRate: 1.0,
      };
    case "development":
    default:
      return {
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      };
  }
};

const { tracesSampleRate, replaysSessionSampleRate, replaysOnErrorSampleRate } =
  getSentryConfig(environment);

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: environment,
  tracesSampleRate: tracesSampleRate,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: replaysSessionSampleRate,
  replaysOnErrorSampleRate: replaysOnErrorSampleRate,
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SettingsProvider>
        <TenantProfileProvider>
          <App />
        </TenantProfileProvider>
      </SettingsProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
