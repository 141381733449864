export interface ReceiptLineItemModel {
  item_name: string;
  amount: number;
  description?: string;
  period_start?: string;
  period_end?: string;
}

export interface PaymentReceiptModel {
  receipt_id: string;
  payment_verification_id?: string;
  payment_intent_id?: string;
  rental_contract_id: string;
  tenant_id: string;
  tenant_name?: string;
  landlord_id: string;
  landlord_name?: string;
  landlord_signature?: string;
  property_unit_id: string;
  property_unit_name?: string;
  property_id?: string;
  property_address?: string;
  receipt_number: string;
  receipt_date: string;
  total_amount: number;
  currency: string;
  payment_method: string;
  payment_status: string;
  line_items: ReceiptLineItemModel[];
  pdf_url?: string;
  html_content?: string;
  is_manually_created: boolean;
  created_by: string;
  created_at?: string;
  updated_at?: string;
  notes?: string;
}

export enum PaymentStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  VOIDED = "VOIDED",
}

export enum PaymentMethod {
  STRIPE = "STRIPE",
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHECK = "CHECK",
  OTHER = "OTHER",
}

export interface PaymentReceiptFilter {
  startDate?: string;
  endDate?: string;
  tenantId?: string;
  propertyUnitId?: string;
  paymentMethod?: PaymentMethod;
  paymentStatus?: PaymentStatus;
  minAmount?: number;
  maxAmount?: number;
}
