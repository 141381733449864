/**
 * Replaces template variables in a string with corresponding values from a data object
 * @param {string} content - The template string containing variables like @PropertyName
 * @param {Object} data - Object containing the replacement values
 * @param {Object} mappings - Object defining custom mappings for template variables
 * @returns {string} - The processed string with all replacements
 */
export const replaceTemplateVariables = (content, data, mappings = {}) => {
  // Default mappings
  const defaultMappings = {
    "@PropertyName": data.property_name,
    "@Portfolio": data.portfolio_name,
    "@StartDate": new Date(data.start_date).toLocaleDateString(),
    "@EndDate": new Date(data.end_date).toLocaleDateString(),
    "@RentAmount": new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(data.monthly_rent),
    "@SecurityDepositAmount": new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(data.security_deposit),
  };

  // Combine default mappings with custom mappings
  const allMappings = { ...defaultMappings, ...mappings };

  // Create a regular expression that matches all template variables
  const pattern = new RegExp(Object.keys(allMappings).join("|"), "g");

  // Replace all matches with their corresponding values
  return content.replace(pattern, (match) => allMappings[match] ?? match);
};
