import React from "react";
import { PencilSquare, Check2Circle } from "react-bootstrap-icons";

const DocumentList = ({ documents, onSignClick, isDarkMode }) => {
  return (
    <div className="tw-mt-4">
      <h5
        className={`tw-mb-3 tw-text-lg tw-font-medium ${
          isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
        }`}
      >
        Document Signatures
      </h5>
      <div className="tw-space-y-3">
        {documents.map((doc) => (
          <div
            key={doc.id}
            className={`tw-p-4 tw-rounded-lg tw-border tw-shadow-sm hover:tw-shadow-md tw-transition-all ${
              doc.highlight
                ? `${
                    isDarkMode
                      ? "tw-border-blue-400 tw-bg-blue-950"
                      : "tw-border-blue-500 tw-bg-blue-50"
                  }`
                : `${
                    isDarkMode
                      ? "tw-border-gray-700 tw-bg-gray-800"
                      : "tw-border-gray-200 tw-bg-white"
                  }`
            }`}
          >
            <div className="tw-flex tw-justify-between tw-items-center">
              <div className="tw-flex tw-items-center">
                <span
                  className={`tw-font-medium ${
                    isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                  }`}
                >
                  {doc.name}
                </span>
                <span
                  className={`tw-ml-2 tw-text-sm ${
                    isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                  }`}
                >
                  ({doc.userType})
                </span>
                {doc.status === "signed" ? (
                  <div className="tw-ml-3 tw-flex tw-items-center tw-text-sm tw-text-green-500">
                    <Check2Circle className="tw-w-4 tw-h-4 tw-mr-1" />
                    <span>Signed on {doc.signedAt}</span>
                  </div>
                ) : (
                  <span className="tw-ml-3 tw-text-sm tw-text-amber-500">
                    Awaiting signature
                  </span>
                )}
              </div>
              {doc.status === "signed" && doc.signatureUrl ? (
                <div className="tw-flex tw-items-center">
                  <img
                    src={doc.signatureUrl}
                    alt={`${doc.name}'s signature`}
                    className={`tw-h-12 tw-object-contain tw-p-1 tw-rounded ${
                      isDarkMode
                        ? "tw-bg-gray-700 tw-border-gray-600"
                        : "tw-bg-white tw-border-gray-100"
                    } tw-border`}
                  />
                </div>
              ) : (
                doc.showSignButton && (
                  <button
                    className="tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded-md tw-text-sm hover:tw-bg-blue-700 tw-transition-colors tw-font-medium"
                    onClick={onSignClick}
                  >
                    Sign Now
                  </button>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentList;
