import React, { useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { devError, devLog } from "utils/logger";

interface StripePaymentFormProps {
  onPaymentSuccess: (paymentIntentId: string) => void;
  onPaymentError: (error: string) => void;
  isDarkMode: boolean;
}

const StripePaymentForm: React.FC<StripePaymentFormProps> = ({
  onPaymentSuccess,
  onPaymentError,
  isDarkMode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    if (!stripe) {
      devLog("Stripe.js hasn't loaded yet.");
      return;
    }

    // Check if we have a payment intent client secret
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      devLog("No client secret found in URL");
      return;
    }

    // Check the payment status
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      devLog("Payment Intent Status:", paymentIntent?.status);
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            onPaymentSuccess(paymentIntent.id);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Please provide your payment details.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      }
    });
  }, [stripe, onPaymentSuccess]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    devLog("Payment submission started");

    if (!stripe || !elements) {
      devLog("Stripe.js hasn't loaded yet.");
      return;
    }

    setIsProcessing(true);
    setMessage("");

    try {
      devLog("Confirming payment...");
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
        redirect: "if_required",
      });

      if (error) {
        devError("Payment error:", error);
        onPaymentError(error.message || "An error occurred during payment.");
        setMessage(error.message || "An error occurred during payment.");
      } else if (paymentIntent) {
        devLog("Payment successful");
        onPaymentSuccess(paymentIntent.id);
      }
    } catch (err: any) {
      console.error("Unexpected error:", err);
      onPaymentError("An unexpected error occurred.");
      setMessage("An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="tw-space-y-6">
      {message && (
        <div
          className={`tw-p-4 tw-rounded-lg tw-text-sm ${
            message.includes("succeeded")
              ? isDarkMode
                ? "tw-bg-green-900 tw-text-green-100"
                : "tw-bg-green-50 tw-text-green-700"
              : message.includes("processing")
              ? isDarkMode
                ? "tw-bg-blue-900 tw-text-blue-100"
                : "tw-bg-blue-50 tw-text-blue-700"
              : isDarkMode
              ? "tw-bg-gray-800 tw-text-gray-300"
              : "tw-bg-gray-50 tw-text-gray-700"
          }`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="tw-space-y-6">
        <div
          className={`tw-rounded-lg ${
            isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
          }`}
        >
          <PaymentElement
            options={{
              layout: {
                type: "tabs",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false,
              },
            }}
          />
        </div>

        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className={`tw-w-full tw-flex tw-justify-center tw-items-center tw-px-6 tw-py-3 tw-rounded-lg tw-text-base tw-font-medium tw-text-white
            ${
              isProcessing
                ? isDarkMode
                  ? "tw-bg-gray-700 tw-cursor-not-allowed"
                  : "tw-bg-gray-400 tw-cursor-not-allowed"
                : isDarkMode
                ? "tw-bg-indigo-500 hover:tw-bg-indigo-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-400"
                : "tw-bg-indigo-600 hover:tw-bg-indigo-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
            }`}
        >
          {isProcessing ? (
            <>
              <svg
                className="tw-animate-spin tw-h-5 tw-w-5 tw-mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="tw-opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="tw-opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Processing...
            </>
          ) : (
            "Pay Now"
          )}
        </button>

        <div
          className={`tw-text-center tw-text-sm ${
            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
          }`}
        >
          <p>Your payment is secure and encrypted</p>
        </div>
      </form>
    </div>
  );
};

export default StripePaymentForm;
