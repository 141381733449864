import http from "../../general/httpService";
import config from "../../../constants/config.json";

///rental_fee_pack/get_rental_fee_pack_by_id/{rental_fee_pack_id}
export async function getRentalFeePackById(rentalFeePackId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/rental_fee_pack/get_rental_fee_pack_by_id/${rentalFeePackId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}
///get_rental_fee_pack_by_campaign_id/{campaign_id}
export async function getRentalFeePackByCampaignId(campaignId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/rental_fee_pack/get_rental_fee_pack_by_campaign_id/${campaignId}`;
  const { data: response } = await http.get(fullApiEndpoint);
  return response;
}
